const tableData = {
  state: {
	 sex:'',
	 e:'',
	 f:'',
     outData: [{
     		prop: 'name',
     		label: '姓名',
     		width: '180'
     	},
     	{
     		prop: 'sex',
     		label: '性别',
     		width: '80'
     	},
     	{
     		prop: 'country',
     		label: '国籍',
     		width: '100'
     	},
     	{
     		prop: 'date',
     		label: '出生日期',
     		width: '165'
     	},
     	{
     		prop: 'idType',
     		label: '证件类型',
     		width: '140'
     	},
     	{
     		prop: 'idnumber',
     		label: '证件号',
     		width: '150'
     	},
     	{
     		prop: 'exp',
     		label: '证件有效期',
     		width: '165'
     	},
     	{
     		prop: 'phone',
     		label: '国家区号-手机号码',
     		width: '220'
     	},
     ],
     tableData: [{
     		name: [{firstName:'Li',lastName:'Chun'}],
     		sex: '女',
     		country: '中国',
     		date: '2016-05-02',
     		idType: '小明',
     		idnumber: '44050215684645',
     		exp: '2016-05-02',
     		phone: [{area:'Li',phone:'Chun'}],
     
     	},
     	{
     		name: [{firstName:'',lastName:''}],
     		sex: '',
     		country: '中国',
     		date: '2016-05-02',
     		idType: '小明',
     		idnumber: '44050215684645',
     		exp: '2016-05-02',
     		phone: [{area:'Li',phone:'Chun'}],
     
     	}
     ],
  },
  getters:{
	   doneTodos: state => {
	        return state.outData
	   },
	   doneTodos2: state => {
	        return state.tableData
	   }
    
  },
  mutations: {
    
  },
  actions: {
     
  } 
  }


export default tableData
