<template>
	<div id="app">
		<router-view name="header" :inputValue='inputValue'></router-view>
		<router-view></router-view>
		<router-view name="footer"></router-view>
	</div>
</template>
<script>
	import Header from './components/header.vue'
	import Footer from './components/footer.vue'
	export default {
		name: 'App',
		components: {
			
		},
		data(){
			return{
				inputValue:0
			}
		},
		created(){  //生命周期里接收参数     
		    console.log('当前地址：'+this.$route.path); 
			
		},
		// mounted() {
		// 	 console.log('当前地址：'+this.$route.path); 
		// 	$(window).scrollTop("0");
		// }
	
	}
</script>

<style lang="scss">

	@import url('~@/style/theme/icon/iconfont.css');
</style>
