<template>
	<div>
		<div class="pageFooter_warp" id="page_footer">
			<div class="seo-box">
				<div class="service-box">
					<dl class="list">
						<dt><a target="_blank" title="一周热门航线"
								href="https://www.yiqifei.com/sitemap/sitemap.html">一周热门航线</a></dt>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_WUH_ADL_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">武汉-阿德莱德</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_CAN_TYO_2016-04-24_2016-04-30_Y&amp;airlinc=&amp;Cabin=Y">广州-东京</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_SYD_CAN_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">悉尼-广州</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_STO_REK_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">斯德哥尔摩-雷克雅未克</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_CAN_LAX_2016-04-24_2016-04-29_Y&amp;airlinc=&amp;Cabin=Y">广州-洛杉矶</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_CAN_SYD_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">广州-悉尼</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_BNE_CNS_2016-04-24_2016-04-26_Y&amp;airlinc=&amp;Cabin=Y">布里斯班-凯恩斯</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_CAN_OSA_2016-04-24_2016-05-02_Y&amp;airlinc=&amp;Cabin=Y">广州-大阪</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_CAN_IST_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">广州-伊斯坦布尔</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_PAR_HAJ_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">巴黎-汉诺威</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_XMN_BKK_2016-04-24_2016-04-28_Y&amp;airlinc=&amp;Cabin=Y">厦门-曼谷</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_BJS_HKT_2016-04-24_2016-05-01_Y&amp;airlinc=&amp;Cabin=Y">北京-普吉岛</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_DUS_CKG_2016-04-24_2016-04-30_Y&amp;airlinc=&amp;Cabin=Y">杜塞尔多夫-重庆</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_WUH_DTT_2016-04-24_2016-05-24_Y&amp;airlinc=&amp;Cabin=Y">武汉-底特律</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_BNE_MEL_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">布里斯班-墨尔本</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_BJS_JKT_2016-04-24_C&amp;airlinc=&amp;Cabin=Y">北京-雅加达</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_YVR_CAN_2016-04-24_2016-05-08_Y&amp;airlinc=&amp;Cabin=Y">温哥华-广州</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_CAN_LAX_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">广州-洛杉矶</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_CAN_SFO_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">广州-旧金山</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_CAN_LAX_2016-04-24_2016-05-06_Y&amp;airlinc=&amp;Cabin=Y">广州-洛杉矶</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_HKG_MLE_2016-04-24_2016-05-02_Y&amp;airlinc=&amp;Cabin=Y">香港-马尔代夫</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_SHA_BKK_2016-04-24_2016-04-30_y&amp;airlinc=&amp;Cabin=Y">上海-曼谷</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=1_YTO_SHA_2016-04-24_2016-08-31_Y&amp;airlinc=&amp;Cabin=Y">多伦多-上海</a>
						</dd>
						<dd><a target="_blank"
								href="https://www.yiqifei.com/loading.aspx?t=0_HKG_CPH_2016-04-24_Y&amp;airlinc=&amp;Cabin=Y">香港-哥本哈根</a>
						</dd>
					</dl>
					<dl class="list">
						<dt><a title="城市机票" target="_blank" href="https://www.yiqifei.com/sitemap/sitemap.html">城市机票</a>
						</dt>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_HKG.html">香港</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_BKK.html">曼谷</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_SIN.html">新加坡</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_CNX.html">清迈</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_KUL.html">吉隆坡</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_LGK.html">兰卡威</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_LON.html">伦敦</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_PAR.html">巴黎</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_AMS.html">阿姆斯特丹</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_MAN.html">曼彻斯特</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_ATH.html">雅典</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_AIL.html">米兰</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_ROM.html">罗马</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_BER.html">柏林</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_SYD.html">悉尼</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_MEL.html">墨尔本</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_PER.html">珀斯</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_AKL.html">奥克兰</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_BNE.html">布里斯班</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_ADL.html">阿德莱德</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_DXB.html">迪拜</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/city_CAI.html">开罗</a></dd>
					</dl>
					<dl class="list">
						<dt><a title="航空公司特价机票" target="_blank"
								href="https://www.yiqifei.com/sitemap/airlineschedule.html">航空公司特价机票</a></dt>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-su.html">俄罗斯航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ua.html">美国联合航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-lh.html">汉莎航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-tp.html">葡萄牙航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ke.html">大韩航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-qf.html">澳洲航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-qr.html">卡塔尔航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-et.html">埃塞俄比亚航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ka.html">港龙航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ga.html">加鲁达航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-os.html">奥地利航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ux.html">西班牙欧洲航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-jl.html">日本航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-az.html">意大利航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-af.html">法国航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-a7.html">西班牙红风筝航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-5j.html">宿务太平洋航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-fm.html">上海航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-mk.html">毛里求斯航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-vv.html">乌克兰航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-pg.html">曼谷航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-um.html">津巴布韦航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-gf.html">海湾航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-kl.html">荷兰皇家航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ms.html">埃及航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-ey.html">阿提哈德航空</a></dd>
						<dd><a target="_blank" href="https://www.yiqifei.com/sitemap/airline-uo.html">香港快运航空</a></dd>
						<dd class="more-info"><a target="_blank"
								href="https://www.yiqifei.com/sitemap/airlineschedule.html">更多航空公司特价机票</a></dd>
					</dl>
				</div>
			</div>
			<div class="pageFooter_box">
				<div style="display: block;" id="pageFooterYiqifeiLink" class="pageFooterYiqifeiLink">
					<div class="hd">
						<span class="title">一起飞国际机票网旗下网站</span><a class="more" target="_blank"
							href="http://accounts.yiqifei.com/Home/Website">查看所有</a>
					</div>
					<div class="clearfix">
						<ul class="fl clearfix">
							<li><a href="http://833.yqf.com" target="_blank">好孕飞</a></li>
							<li><a href="http://269.yqf.com" target="_blank">趣欧旅游</a></li>
							<li><a href="http://380.yqf.com" target="_blank">英游假日</a></li>
							<li><a href="http://678.yqf.com" target="_blank">浮潜自由行</a></li>
							<li><a href="http://799.Yqf.com" target="_blank">雪上假期</a></li>
							<li><a href="http://111.yqf.com" target="_blank">品欧假期</a></li>
							<li><a href="http://333.yqf.com" target="_blank">约闺蜜出游</a></li>
							<li><a href="http://610.yqf.com" target="_blank">小脚印亲子游</a></li>
							<li><a href="http://222.m.yqf.com" target="_blank">会演游</a></li>
							<li><a href="http://520.yqf.com" target="_blank">红豆雨蜜月游</a></li>
							<li><a href="http://527.yqf.com" target="_blank">婚飞假日</a></li>
							<li><a href="http://500.yqf.com" target="_blank">游澳澳</a></li>
							<li><a href="http://555.yqf.com" target="_blank">阳光海滩假日</a></li>
							<li><a href="http://828.yqf.com" target="_blank">夜猫旅行管家</a></li>
							<li><a href="http://666.yqf.com" target="_blank">欧铁行</a></li>
							<li><a href="http://168.yqf.com" target="_blank">飞非差旅</a></li>
							<li><a href="http://199.yqf.com" target="_blank">夏日飞飞</a></li>
							<li><a href="http://388.yqf.com" target="_blank">魅力中东非</a></li>
							<li><a href="http://747.yqf.com" target="_blank">非凡会展通全球</a></li>
							<li><a href="http://789.yqf.com" target="_blank">澳新梦之旅</a></li>
							<li><a href="http://808.yqf.com" target="_blank">头等商务飞</a></li>
							<li><a href="http://138.m.yqf.com" target="_blank">非魅出行</a></li>
							<li><a href="http://705.yqf.com" target="_blank">享趣海岛</a></li>
							<li><a href="http://178.yqf.com" target="_blank">济出州末</a></li>
							<li><a href="http://777.yqf.com" target="_blank">游美网</a></li>
							<li><a href="http://888.yqf.com" target="_blank">海龟留学游</a></li>
							<li><a href="http://517.yqf.com" target="_blank">聚美旅行</a></li>
							<li><a href="http://177.yqf.com" target="_blank">签行网</a></li>
							<li><a href="http://787.yqf.com" target="_blank">假日高球</a></li>
							<li><a href="http://999.yqf.com" target="_blank">哈哈游澳新</a></li>
						</ul>
						<div class="fr">
							<div class="code-box clearfix">

								<div class="code codemg"><img src="//tools.yiqifei.com/images/phone_code.png">
									<p>一起飞手机网络版</p>
								</div>
								<div class="code codemg"><img src="//tools.yiqifei.com/images/app/code151224.png">
									<p>一起飞APP</p>
								</div>
								<div class="code codemg"><img src="//tools.yiqifei.com/images/xiaochengyu.jpg">
									<p>一起飞小程序</p>
								</div>

							</div>
							<div class="pageFooter_phone">
								<p><label>会员服务及投诉建议</label></p>
								<p>预订热线： <span>400-678-6622</span><span>020-22813599</span></p>
								<p><label>E-mail：</label>VIP@Yqf.cn </p>
							</div>
						</div>
					</div>
				</div>
				<div class="pageFooter">
					<div class="pageFooter_main">
						<div class="pageFooter_SR">
							<ul>
								<li><a title="IATA成员" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit01.jpg">
										<p>IATA成员</p>
									</a></li>
								<li><a title="广东省著名商标" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit02.jpg">
										<p>广东省著名商标</p>
									</a></li>
								<li><a title="ET最佳分销奖" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit03.jpg">
										<p>ET最佳分销奖</p>
									</a></li>
								<li><a title="用户满意服务奖" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit04.jpg">
										<p>用户满意服务奖</p>
									</a></li>
								<li><a title="电子商旅专家奖" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit05.jpg">
										<p>电子商旅专家奖</p>
									</a></li>
								<li><a title="星空联盟卓越代理" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit06.jpg">
										<p>星空联盟卓越代理</p>
									</a></li>
								<li><a title="企业信用等级认证" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit07.jpg">
										<p>企业信用等级认证</p>
									</a></li>
								<li><a title="全国诚信代理企业" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit08.jpg">
										<p>全国诚信代理企业</p>
									</a></li>
								<li><a title="GATA副理事单位 " href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit09.jpg">
										<p>GATA副理事单位</p>
									</a></li>
								<li><a title="广旅协会会员" href="http://ab.yiqifei.com/honors.html" target="_blank"
										class="pageFooter_SR_ico"><img src="//tools.yiqifei.com/images/credit10.jpg">
										<p>广旅协会会员</p>
									</a></li>
							</ul>
						</div>
						<div class="pageFooterLelft">
							<div class="pageFooter_BA">

								<a target="_blank" title="可信网站示范单位"
									href="https://ss.knet.cn/verifyseal.dll?sn=e13102311010043007jxrr000000&amp;ct=df&amp;a=1&amp;pa=0.35302965130443253"
									name="homepage_weibu_kexwzsfdw24">
									<img src="//image4.suning.cn/images/ShoppingArea/Common/morelogo2.png">
								</a>
								<a target="_blank"
									href="http://guangzhou.cyberpolice.cn/netalarm/netalarm/wangjian_index.jsp">
									<img alt="" src="//tools.yiqifei.com/images/wj/ktjc.gif">
									<img class="f_wj_img" alt="" src="//tools.yiqifei.com/images/wj/cc1.gif">
									<img alt="" src="//tools.yiqifei.com/images/wj/jj1.gif" class="f_wj_img">
								</a>&nbsp;&nbsp;&nbsp;
							</div>
						</div>
						<div class="pageFooterRight"></div>
					</div>
					<div style="clear: both;"></div>

				</div>
				<div class="pageFooter_link">
					<ul style="width: 700px;">
						<li style="border: none;"><a target="_blank" href="http://ab.yiqifei.com/">关于我们</a></li>
						<li><a target="_blank" href="http://www.yiqifei.com/sitemap/">网站地图</a></li>
						<li><a target="_blank" href="http://t.sina.com.cn/yiqifeicas">新浪官方微博</a></li>
						<li><a target="_blank" href="http://ab.yiqifei.com/contact_us.html">诚聘英才</a></li>
						<li><a target="_blank" href="http://tour.yiqifei.com/Page/HelpCenter/">帮助中心</a></li>
						<li><a target="_blank" href="http://www.yiqifei.com/friendly.htm">友情链接</a></li>
						<li><a target="_blank" href="https://mail.google.com/a/yiqifei.com/">电子邮箱A</a></li>
						<li><a target="_blank" href="http://exmail.qq.com/login">电子邮箱B</a></li>
					</ul>
				</div>
				<div class="pageFooter_copyright">
					Copyright © 2000-2016 Yiqifei.com 一起飞 all rights reserved.
					<a style="color: #fbfbfb;" id="a_pagefooter_icp" target="_blank"
						href="https://beian.miit.gov.cn">粤ICP备10020014号</a>
					<a style="color: #fbfbfb;" target="_blank"
						href="http://about.yiqifei.com/UpImages/dxzicp.jpg">增值电信业务经营许可证</a>
					<a style="color: #fbfbfb;" target="_blank"
						href="http://guangzhou.cyberpolice.cn/netalarm/netalarm/Welcome.jsp?bano=4401040100080">网站安全备案</a>
					<a style="color: #fbfbfb;" name="LkBgb8Qjx8WuJwW9u913lViEprKVURnShZifH4SITDyRzUEvCx"
						href="http://www.anquan.org/s/www.yiqifei.com">安全联盟</a>
				</div>
			</div>
		</div>
		
		<!-- <div class="fixbox" id="kefu_box">
			<a href="/AjaxData/JumpToFeiFei" target="_blank" class="mb30">
				<img src="/assets/online_counsel.png" class="addmove" alt="Alternate Text" width="22px">
				<p class="ft12 mt5">在线客服</p>
			</a>
			<a href="javascript:;"  @click="toOpen(1)" class="mb30" >
				<img src="/assets/feedback.png" alt="Alternate Text" width="20px">
				<p class="ft12 mt5">意见反馈</p>
			</a>
			<a href="javascript:;"  @click="toOpen(2)" style="margin-bottom:15px;">
				<span class="iconfont icon-anquan ft20"></span>
				<p class="ft12">
					旅游安全<br>提示</p>
			</a>
			<a href="javascript:;"  @click="toOpen(3)"  style="margin-bottom:15px;">
				<span class="iconfont icon-zhifuanquan icon-payanquan ft20"></span>
				<p class="ft12">
					支付安全<br>提示</p>
			</a>
			<a href="javascript:;" @click="toTo()">
				<span class="iconfont icon-chalv-xiangshang icon-xiangshang ft20"></span>
				<p class="ft12">TOP</p>
			</a>
		</div> -->
		
		
	</div>
</template>

<script>
	import {
		getAccountInfo
	} from '@/utils/localdatas';
	
	var that;
	var util = require('@/utils/util.js');

	export default {
		name: 'Footer',
		props: ['whiteStyle', 'mon'],
		data() {
			return {
				gotop: false,
				showFeedback: false,
				ActiveIndex: 1,
				fit: 'fill',
				feedback: ['产品预订类', '客户服务类', '平台体验类', '其他'],
				form: {					
					feedbackContent: '',
					name: '',
					phone: '',
					sex: '1',
					email: '',
					imageUrl: '',
					productId: 0
				},
				accountinfo: getAccountInfo(),
				textarea: "",
				imageUrl: '',
				radio: '',
				formLabelAlign: [],
				i:"",
				btnLoading:false
			};
		},
		created() {
			//生命周期里接收参数
			that = this;
			if (!util.isNullOrEmpty(that.accountinfo)) {
				that.form.name = that.accountinfo.Name;
				that.form.phone = that.accountinfo.Mobile;
				that.form.email = that.accountinfo.Email;
			}
		},
		mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			window.addEventListener('scroll', this.handleScroll, true);
		},
		components: {
			
		},
		methods: {
			// handleAvatarSuccess(res, file) {
			// 	this.imageUrl = URL.createObjectURL(file.raw);
			// },
			// beforeAvatarUpload(file) {
			// 	const isJPG = file.type === 'image/jpeg';
			// 	const isLt2M = file.size / 1024 / 1024 < 2;

			// 	if (!isJPG) {
			// 		this.$message.error('上传头像图片只能是 JPG 格式!');
			// 	}
			// 	if (!isLt2M) {
			// 		this.$message.error('上传头像图片大小不能超过 2MB!');
			// 	}
			// 	return isJPG && isLt2M;
			// },
			//返回顶部
			toTo() {
				$(window).scrollTop("0");
			},
			toOpen(index){
				that.i=index
			},
			closeOpen(){
				that.i=""
			},
			ClickActive(e) {
				console.log(e);
				that.form.productId = e;
			},
			// handleRemove(file, fileList) {
			// 	console.log(file, fileList);
			// },
			// handlePictureCardPreview(file) {
			// 	this.dialogImageUrl = file.url;
			// 	this.dialogVisible = true;
			// },

			//上传图片结果
			handleAvatarSuccess(res, file) {
				console.log(res);
				// this.imageUrl = URL.createObjectURL(file.raw);
				if (res.code == 0) {
					that.form.imageUrl = res.data.imgFileList[0].ImgUrl;
				} else {
					that.$message.error(res.msg);
				}
			},
			beforeAvatarUpload(file) {
				console.log(file);
			},

			handleScroll() {
				let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;

				scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
			},
			toTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			bindJumpFeiFei(e) {
				let url = util.apiUrl + "/MemberAPI/FeiFei?UserCode=" + util.getAccountCode();

				window.open(url);
			},
			bindSubmitFeedback(e) {
				console.log(that.form);
				let formData = that.form;
				if (formData.feedbackContent == '') {
					that.$alert('请填写您要反馈的内容');
					return;
				}
				if (formData.name == '') {
					that.$alert('请填写您的称呼');
					return;
				}

				var parm = {
					Email: formData.email,
					Mobile: formData.phone,
					CustomerTitle: '',
					Content: formData.feedbackContent,
					CustomerName: formData.name,
					FeedBackTypeID: formData.productId + 1,
					imglist_box_feedback: formData.imageUrl
				};
				that.btnLoading=true;
				SubmitFeedBack(parm, function(result) {
					that.btnLoading=false;
					if (result.code == 0) {
						that.$alert('感谢您的反馈,我们的客服会尽快处理', {
							confirmButtonText: '确定',
							callback: action => {
								that.showFeedback = false;
							}
						});
					} else {
						that.$alert(result.msg);
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	.int-foot.bgwhite {
		background: #fff;
		color: #000;
		height: 70px;

		a {
			color: #000;
		}

		margin-top: 20px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 90px;
		line-height: 90px !important;
		text-align: center;
	}

	// .avatar {
	// 	width: 178px;
	// 	height: 178px;
	// 	display: block;
	// }
</style>
