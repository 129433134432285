var datehelper = require('@/utils/datehelper.js');
var date_now = new Date();
var date1 = datehelper.DateTimeParse(date_now.addDays(5)).toString('yyyy-MM-dd');
var date2 = datehelper.DateTimeParse(date_now.addDays(10)).toString('yyyy-MM-dd');
export var duals = [{
		value: '0',
		label: '单程'
	},
	{
		value: '1',
		label: '往返程'
	},
	{
		value: '2',
		label: '多航段'
	}
];
export var canbins = [{
		value: '',
		label: '全部舱位'
	},
	{
		value: 'Y',
		label: '经济舱'
	},
	{
		value: 'P',
		label: '高端经济舱'
	},
	{
		value: 'C',
		label: '商务舱'
	},
	{
		value: 'F',
		label: '头等舱'
	}
];

export var adults = [{
		value: 1,
		label: '1成人'
	},
	{
		value: 2,
		label: '2成人'
	},
	{
		value: 3,
		label: '3成人'
	},
	{
		value: 4,
		label: '4成人'
	},
	{
		value: 5,
		label: '5成人'
	},
	{
		value: 6,
		label: '6成人'
	},
	{
		value: 7,
		label: '7成人'
	},
	{
		value: 8,
		label: '8成人'
	},
	{
		value: 9,
		label: '9成人'
	}
];
export var childrens = [{
		value: 0,
		label: '儿童2-12岁'
	},
	{
		value: 1,
		label: '1儿童'
	},
	{
		value: 2,
		label: '2儿童'
	},
	{
		value: 3,
		label: '3儿童'
	}
];
/*现金账户*/
export var payTypeList = [{
		name: '现付',
		value: 1,
		balance: '',
		balanceName: '',
		classStyle: 'icom-payment',
		isChecked: true
	},
	{
		name: '挂账',
		value: 2,
		balance: 0,
		balanceName: '企业剩余额度',
		classStyle: 'icon-monthly',
		isChecked:false
	},
	{
		name: '现金账户',
		value: 3,
		balance: 0,
		balanceName: '余额',
		classStyle: 'icon-monthly',
		isChecked:false
	}
];
//配送方式
export var deliveryArray = [{
		value: '3',
		name: '无需配送'
	},
	{
		value: '1',
		name: '上门自取'
	},
	{
		value: '2',
		name: '送票上门'
	}
];
//联系人信息
export var deliverGoodsInfo = {
	SendLinkMan: '',
	SendLinkMobile: '',
	SendLinkEmail: '',
	SendLinkAddress: '',
	SendLinkManSex: '1',
	SendPost: '',
	SendLinkRemark: '',
	fapiao: '',
	SendType: '',
	AreaCode: '',
	AreaCity: '',
};
//火车票查询信息
export var TrainTicketSearchInfo = {
	"fromStation": "广州",
	"toStation": "上海",
	"searchType": 0,
	"ticketType": 1,
	"trainType": 1,
	"trainDate": date1,
};
//保险查询信息
export var InsurSearchInfo = {
	"SessionKey": "_InsurSearchInfoKey",
	"StartDate": date1,
	"EndDate": date2,
	"Days": 3,
	"ProductName": null,
	"InsuredType": null,
	"Provider": null,
	"PlanLabel": null,
	"IsNetworkSale": false,
	"DestinationContinent": null,
	"DestinationCountry": null,
	"PopularMin": 0,
	"PopularMax": 0,
	"ProductCode": null,
	"PlanCode": null,
	"AdultQty": 0,
	"ChildQty": 0,
	"GoodOwnerCode": null,
	"GoodOwnerName": null
};
//机票查询信息
export var TicketSearchInfo = {
	"SortType": 1,
	"CompanyCode": "",
	"Dual": "0",
	"DualType": "1",
	"DepartCityCode": "CAN",
	"DepartCityName": "广州",
	"DepartInputVal": "广州(CAN)",
	"ArrivalCityCode": "SYD",
	"ArrivalCityName": "悉尼",
	"ArrivalInputVal": "悉尼(SYD)",
	"StartDate": date1,
	"ReturnDate": date2,
	"Airline": "",
	"DC_Code": "",
	"DC_Name": "",
	"DC_Add": 0,
	"CurrencyType": "CNY",
	"Cabin": "Y",
	"UserKind2": "1",
	"Version": "1",
	"BrowseCode": "",
	"CacheID": null,
	"Adults": 1,
	"Children": 0,
	"Student": 0,
	"iscombine": true,
	"IsChinaCity": false,
	"GoodsType": "INTLAIR",
	"MultiDepartCountryCode": null,
	"MultiDepartCityCode": ["CAN", "SYD"],
	"MultiDepartCityName": null,
	"MultiArrivalCountryCode": null,
	"MultiArrivalCityCode": ["SYD", "CAN"],
	"MultiArrivalCityName": null,
	"MultiStartDate": [date1, date2],
	"DepartCountryCode": "CN",
	"DepartCountryName": null,
	"ArrivalCountryCode": "AU",
	"ArrivalCountryName": null,
	"MultFlightCount": 2,
	"TicketsSessionKey": "__yiqifeiCom_TicketsInfo_fhlocal.yiqifei.com",
	"DC_CodeSessionKey": "yiqifeiabs_com_dccodeKey",
	"MultSegments": [{
		"DepartCityCode": "CAN",
		"DepartCityName": "广州",
		"DepartInputVal": "广州(CAN)",
		"ArrivalCityCode": "SYD",
		"ArrivalCityName": "悉尼",
		"ArrivalInputVal": "悉尼(SYD)",
		"DepartDate": date1,
		"DepartDateStr": "11月22日 周日"
	}, {
		"DepartCityCode": "SYD",
		"DepartCityName": "悉尼",
		"DepartInputVal": "悉尼(SYD)",
		"ArrivalCityCode": "CAN",
		"ArrivalCityName": "广州",
		"ArrivalInputVal": "广州(CAN)",
		"DepartDate": date2,
		"DepartDateStr": "11月27日 周五"
	}],
	IsChange: false,
	OrderNo: ''
};

//酒店查询信息
export var HotelSearchInfo = {
	"_RoomCount": 1,
	"ID": null,
	"IsChinaCity": false,
	"SearchTime": "0001-01-01 00:00:00",
	"SessionKey": "_HotelSearchInfo",
	"CheckInDate": date1,
	"CheckOutDate": date2,
	"CheckInDateStr": date1,
	"CheckOutDateStr": date2,
	"Countrycode": "TH",
	"Citycode": "BKK",
	"CityName": "曼谷",
	"DistrictCode": "THBKK",
	"Day": 2,
	"RoomCount": 1,
	"AdultCount": 1,
	"ChildCount": 0,
	"RoomList": [{
		"AdultCount": 1,
		"ChildCount": 0,
		"ChildAge": null
	}],
	"KeyWord": "",
	Latitude: "",
	Longitude: ""
};

//产品类型
export var ProductCategorys = [{
		value: '',
		label: '全部订单'
	},
	{
		value: '9',
		label: '国际机票'
	},
	{
		value: '8',
		label: '国内机票'
	},
	{
		value: '2',
		label: '酒店'
	},
	{
		value: '3',
		label: '车船票'
	},
	{
		value: '4',
		label: '保险'
	},
	{
		value: '5',
		label: '欧铁通票'
	},
	{
		value: '6',
		label: '欧铁点对点'
	}
];
