<template>
	<div class="pageHeader-all-box">
		<div class="pageHeader-top">
			<div class="container">
				<div class="zhuzhan-header">
					<div class="contact-box">
						<el-row type="flex" justify="space-between">
							<div>
								<span>24小时服务热线：</span>
								<strong class="red">400-678-6622</strong>
								<a class="contact-list ml10" href="https://diy.yiqifei.com/addsimpleorder.aspx"
									title="写信给我们" target="_blank">
									<img src="https://tools.yiqifei.com/images/xinfeng.gif" class="i-middle" />
									<em class="i-middle">写信给我们</em>
								</a>
								<el-dropdown class="ml10">
									<span class="el-dropdown-link"><img
											src="//tools.yiqifei.com/images/weixin_s.gif" /></span>
									<el-dropdown-menu slot="dropdown">
										<div class="weixinbox"><img src="//tools.yiqifei.com/images/weixin.jpg"
												alt="" /></div>
									</el-dropdown-menu>
								</el-dropdown>
								<div class="loginstate" v-if="AccountInfo==null">
									<a href="javascript:;" @click="bindGetLogin">登录</a>
									&nbsp;
									<!-- /&nbsp;<a href="javascript:;" @click="bindRegister">注册</a> -->
								</div>
							</div>
							<div>
								<block v-if="AccountInfo!=null">
									<el-dropdown style="font-size:12px;" @command="handleCommand">
										<span class="el-dropdown-link">
											我的帐户
											<span class="iconfont icon-xiala"></span>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="https://accounts.yiqifei.com/">帐户首页
											</el-dropdown-item>
											<el-dropdown-item command="https://accounts.yiqifei.com/Order">我的订单
											</el-dropdown-item>
											<el-dropdown-item
												command="https://accounts.yiqifei.com/Account/MyIntergral">我的积分
											</el-dropdown-item>
											<el-dropdown-item command="https://accounts.yiqifei.com/">我的空间
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</block>
								<span class="ml10" @click="bindJifenJiangli">积分奖励</span>
								<!-- <span class="ml10">员工家园</span> -->
								<!-- <span class="link">繁體版(港幣價)</span> -->
							</div>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<div class="pageHeader-middle container clearfix">
			<el-row type="flex" justify="space-between">
				<el-row type="flex">
					<div class="logobox">
						<a href="http://www.yiqifei.com/" class="logoimg">
							<!-- <img src="//tools.yiqifei.com/images/logo.gif"
								title="一起飞国际机票网：为您提供全球300多家航空公司往返约192个国家、4088个城市的特价机票" /> -->
							<img src="../assets/79d77542373a86ae444e2dd7b8402cd.png"
								title="一起飞国际机票网：为您提供全球300多家航空公司往返约192个国家、4088个城市的特价机票" />
						</a>
					</div>
					<!-- <div class="actbox" style="padding:10px 0px 0px 10px;"><img src="../assets/tunian.png"
							style="height:70px;width: 180px;" /></div> -->

				</el-row>
				<div class="activebox">
					<el-row type="flex" align="middle">
						<div class="actbox mt10" style="font-size: 16px;font-weight:700;min-width:180px;color:#e43a3d;">
							<a target="_blank" href="http://ab.yiqifei.com/news/detail-3.html" style="color:#e43a3d;border:1px solid #e43a3d;border-radius:4px;display:block;text-align:center;margin-right:10px;">
								<div>慎防机票预订欺诈行为</div>
								<div>认准一起飞®官方渠道</div>
							</a>
						</div>
						<iframe class="active-frame" marginwidth="0" marginheight="0" frameborder="0"
							src="//tools.yiqifei.com/transfer/titlead.aspx?adcode=19" allowtransparency="true"
							scrolling="no"></iframe>
					</el-row>
				</div>
			</el-row>
		</div>
		<div class="pageHeader-bottom">
			<div class="int-nav-header">
				<el-row class="int-nav container" type="flex">
					<a href="javascript:;" @click="bindLink(item.link)" v-for="(item, index) in headNavs" :key="index"
						:to="item.link" :class="item.link == path ? 'current' : ''">
						{{ item.name }}
					</a>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	import {
		getAccountInfo,
		setAccountInfo,
		clearLoginInfo
	} from '@/utils/localdatas';
	import {
		GetHeadNav,
		GetMessageList,
		GetNotice
	} from '@/api/memberApi';

	import Vuex from 'vuex';

	var that;
	var webconfig = require('@/utils/webconfig.js');

	export default {
		name: 'Header',
		props: {
			msg: String,
			inputValue: String
		},
		setup(props) {
			console.log(props.inputValue);
		},
		watch() {
			that.path = this.$store.state.routerName;
			console.log(1);
		},
		data() {
			return {
				returnurl: '/',
				path: '',
				headNavs: [{
						name: '首页',
						link: '/'
					},
					{
						name: '机票',
						link: '/Flight'
					},
					// { name: '国内机票', link: '/DomesticAir' },
					{
						name: '全球酒店',
						link: '/Hotel'
					},
					{
						name: '欧洲火车',
						link: 'http://tour.yiqifei.com/Europe'
						// link: '/Europe'
					},
					{
						name: '签证',
						// link:'http://tour.yiqifei.com/Visa'
						link: '/Visa'
					},
					// {
					// 	name: '保险',
					// 	link: '/Insurance'
					// },
					{
						name: '差旅宝',
						link: 'https://biz.yiqifei.com'
					},
					{
						name: '支付中心',
						link: 'https://pay.yiqifei.com'
					},
					{
						name: '关于我们',
						link: 'http://ab.yiqifei.com'
					}
				],
				noticeCount: '',
				NoticeText: '',
				AccountInfo: null
			};
		},
		created(props) {
			//生命周期里接收参数
			that = this;
			that.path = that.$route.path;
			console.log(that.path);
			that.AccountInfo = getAccountInfo();
			console.log(that.AccountInfo);
			// console.log('1111' + that.$route.path);
		},
		methods: {
			handleCommand(command) {
				// this.$message('click on item ' + command);
				window.location.href = command;
			},
			bindJifenJiangli: function(e) {
				window.location.href = "http://gift.yiqifei.com/";
			},
			bindLink: function(e) {
				console.log(e);
				let path = e;

				if (path.indexOf('http') != -1) {
					window.location.href = path;
				} else {
					that.path = path;
					that.$router.push({
						path: path
					});
				}
			},
			bindNoticeCenter(e) {
				that.$router.push({
					path: '/Profile/NoticeCenter'
				});
			},
			logout(e) {
				console.log(that.$route.path);
				clearLoginInfo();
				that.$router.push({
					path: '/login',
					query: {
						logout: true,
						returnurl: that.$route.path
					}
				});
			},
			logo() {
				console.log(1111);
				console.log(that.$route);
				var path = that.$route.path;
				that.path = this.$store.state.routerName;
				console.log(that.path + '44444');
				console.log(this.$store.state.routerName);
			},
			//登录
			bindGetLogin: function(e) {
				console.log(that.$route.path);
				// var returnurl = that.$route.path;
				var returnurl = encodeURIComponent(window.location.href);
				// var loginurl=webconfig.yqfComApiurl+'/Account/JumpLogin?returnurl='+returnurl;
				// window.location.href=loginurl;
				util.getLogin(returnurl);
			},
			//注册
			bindRegister: function(e) {
				var returnurl = that.$route.path;

			}
		}
	};

	function getHeadNav(accountno) {
		GetHeadNav(null, function(result) {
			if (result.code == 0) {
				that.headNavs = result.data.headNavs;
			}
		});
	}

	function getMessageList(accountno) {
		let parm = {
			accountno: accountno
			// accountno: 'CMC0K38F',
			// pageindex:that.pageindex
		};
		GetMessageList(parm, function(result) {
			if (result.code == 0) {
				that.noticeCount = result.data.pageCount;
				if (that.noticeCount > 99) {
					that.noticeCount = '99+';
				}
			}
		});
	}

	function getNotice(accountno) {
		GetNotice(null, function(result) {
			if (result.code == 0) {
				that.NoticeText = result.data.NoticeText;
			}
		});
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
