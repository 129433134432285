// import Vue from 'vue'
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)


//本地存储
function setStorage(key,obj) {
	// this.$cookie.set(key, obj, 1);
	if(typeof obj == 'object'){
		// console.log(JSON.stringify(obj));
		obj=JSON.stringify(obj);
	}
	localStorage.setItem(key, obj);
}

function getStorage(key) {
	// return this.$cookie.get(key);
	var obj=localStorage.getItem(key);
	if(obj == null || obj == '' || obj == undefined || obj == "null"){
		return null;
	}
	try{
		obj=JSON.parse(obj);
	}catch{
		// return null;
	}
	return obj;
}
function deleteStorage(key) {
	// this.$cookie.delete(key);
	localStorage.removeItem(key);
}
//临时存储
function setSessionStorage(key,obj) {
	// this.$cookie.set(key, obj, 1);
	if(typeof obj == 'object'){
		// console.log(JSON.stringify(obj));
		obj=JSON.stringify(obj);
	}
	sessionStorage.setItem(key, obj);
}

function getSessionStorage(key) {
	// return this.$cookie.get(key);
	var obj=sessionStorage.getItem(key);
	if(obj == null || obj == '' || obj == undefined || obj == "null"){
		return null;
	}
	try{
		obj=JSON.parse(obj);
	}catch{
		// return null;
	}
	return obj;
}


export {
	//通用属性
	setStorage,
	getStorage,
	deleteStorage,
	setSessionStorage,
	getSessionStorage
}