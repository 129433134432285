<template>
	<el-dialog class="des-modal" :visible.sync="titleRight" :width="widthNum" :show-close="showClose" :title="title" left :before-close="clickDel">
		<div>
			<span v-if="$slots.default"><slot></slot></span>
			<div class="text-c" style="padding-bottom: 40px;" v-if="footerNone == true">
				<el-button style="width: 120px;" @click="clickDel" v-if="delShow == true">取消</el-button>
				<el-button type="danger" class="button mt25" style="width: 120px;" @click="clickAction">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
export default {
	name: 'DesModal',
	props: ['titleRight', 'widthNum', 'footerNone', 'showClose', 'delShow', 'title'],
	data() {
		return {};
	},
	methods: {
		clickAction() {
			this.$emit('clickHeadAction', this.titleRight, this.widthNum);
		},
		clickDel() {
			this.$emit('clickDelAction', this.titleRight, this.widthNum);
		}
	},
	created() {
		// this.$slots 包含所有插槽使用的数组 在改测试中返回 {default: Array(2), up: Array(4)}
		// 在父组件中使用默认插槽2次，up插槽4次
		// this.$slots.default 返回默认插槽的数组 {default: Array(2)}
		// elment-ui el-button 中的用法  <span v-if="$slots.default"><slot></slot></span>
		// console.log(this.$slots, this.$slots.default.length, 'slots');
	}
};
</script>
<style></style>
