/*通用方法类库*/

/*判断*/

//判断对象是否为空
export function isNullOrEmpty(obj) {
	return obj == null || obj == '' || obj == undefined || obj == "null";
}
export function isLocal() {
	let url=window.location.href;
	if(url.indexOf('local')!=-1){
		return true;
	}
	return false;
}

//获取唯一标识
export function getNewGuid() {
	var guid = "";
	for (var i = 1; i <= 32; i++) {
		var n = Math.floor(Math.random() * 16.0).toString(16);
		guid += n;
		if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
			guid += "-";
	}
	return guid;
}

export function getIDCardInfo(idCard) {
	if (isNullOrEmpty(idCard)) {
		return null;
	}
	if (!checkIDCard(idCard)) {
		return null;
	}
	try {
		var carcInfo = {
			birthday: "",
			age: "",
			sex: ""
		};
		//获取生日
		let birthday = "";
		if (idCard.length == 15) {
			birthday = "19" + idCard.substr(6, 6);
		} else if (idCard.length == 18) {
			birthday = idCard.substr(6, 8);
		}
		carcInfo.birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
		//获取性别
		if (parseInt(idCard.substr(16, 1)) % 2 == 1) {
			//男
			carcInfo.sex = "男";
		} else {
			//女
			carcInfo.sex = "女";
		}
		//获取年龄
		var myDate = new Date();
		var month = myDate.getMonth() + 1;
		var day = myDate.getDate();
		var age = myDate.getFullYear() - idCard.substring(6, 10) - 1;
		if (idCard.substring(10, 12) < month || idCard.substring(10, 12) == month && idCard.substring(12, 14) <= day) {
			age++;
		}
		carcInfo.age = age;

		return carcInfo;
	} catch (e) {
		console.log(e);
		return null;
	}
}

//判断身份证
export function checkIDCard(idCard) {
	var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
	if (reg.test(idCard) === false) {
		console.log('身份证输入不合法');
		return false;
	} else {
		console.log('合法');
		return true;
	}
}
//根据日期获取年龄
export function getAgeByBirthday(birthday) {
	/**
	 * @uses 根据生日计算年龄，年龄的格式是：2016-09-23
	 * @param string birthday
	 * @return string|number
	 **/
	let iage = 0;
	if (!isNullOrEmpty(birthday)) {
		//获取出生日期的年月日
		let year = getYmd(birthday, 'y');
		let month = getYmd(birthday, 'm');
		let day = getYmd(birthday, 'd');

		//获取当前时间的年月日
		let myDate = new Date();
		let now_year = myDate.getFullYear();
		let now_month = myDate.getMonth() + 1;
		let now_day = myDate.getDate();

		if (now_year > year) {
			iage = now_year - year - 1;
			if (now_month > month) {
				iage++;
			} else if (now_month == month) {
				if (now_day >= day) {
					iage++;
				}
			}
		}
	}
	return iage;

}
/**
 * @uses 根据指定日期返回你年月日，日期格式是：2016-09-23
 * @param string s 日期字符串
 * @param string type 返回的 年y 月m 日d
 * @return string|number
 **/
function getYmd(s, type) {
	// var s = "2010-5-6";
	let a = s.split("-");
	if (a.length > 2) {
		let y = a[0];
		let m = a[1];
		let d = a[2];
		// console.log('y:' + y, 'm:' + m, 'd:' + d)
		let returnStr = '';
		switch (type) {
			case 'y':
				returnStr = y;
				break;
			case 'm':
				returnStr = m;
				break;
			case 'd':
				returnStr = d;
				break;
			default:
				returnStr = '';
				break;
		}
		return returnStr;
	} else {
		return '';
	}
}

/**
 * 去除空格
 * @param {str}
 * @param {type} 
 *    type: 1-所有空格 2-前后空格 3-前空格 4-后空格
 * @return {String}
 */
export function trim(str, type) {
	type = type || 1
	switch (type) {
		case 1:
			return str.replace(/\s+/g, "");
		case 2:
			return str.replace(/(^\s*)|(\s*$)/g, "");
		case 3:
			return str.replace(/(^\s*)/g, "");
		case 4:
			return str.replace(/(\s*$)/g, "");
		default:
			return str;
	}
}
/**
 * @param {str} 
 * @param {type}
 *    type: 1:首字母大写 2：首页母小写 3：大小写转换 4：全部大写 5：全部小写
 * @return {String}
 */
export function changeCase(str, type) {
	type = type || 4
	switch (type) {
		case 1:
			return str.replace(/\b\w+\b/g, function(word) {
				return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
			});
		case 2:
			return str.replace(/\b\w+\b/g, function(word) {
				return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
			});
		case 3:
			return str.split('').map(function(word) {
				if (/[a-z]/.test(word)) {
					return word.toUpperCase();
				} else {
					return word.toLowerCase()
				}
			}).join('')
		case 4:
			return str.toUpperCase();
		case 5:
			return str.toLowerCase();
		default:
			return str;
	}
}
/*
  检测密码强度
*/
export function checkPwd(str) {
	var Lv = 0;
	if (str.length < 6) {
		return Lv
	}
	if (/[0-9]/.test(str)) {
		Lv++
	}
	if (/[a-z]/.test(str)) {
		Lv++
	}
	if (/[A-Z]/.test(str)) {
		Lv++
	}
	if (/[\.|-|_]/.test(str)) {
		Lv++
	}
	return Lv;
}
/*过滤html代码(把<>转换)*/
export function filterTag(str) {
	str = str.replace(/&/ig, "&amp;");
	str = str.replace(/</ig, "&lt;");
	str = str.replace(/>/ig, "&gt;");
	str = str.replace(" ", "&nbsp;");
	return str;
}

/*随机数范围*/
export function random(min, max) {
	if (arguments.length === 2) {
		return Math.floor(min + Math.random() * ((max + 1) - min))
	} else {
		return null;
	}
}
/*将阿拉伯数字翻译成中文的大写数字*/
export function numberToChinese(num) {
	var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
	var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
	var a = ("" + num).replace(/(^0*)/g, "").split("."),
		k = 0,
		re = "";
	for (var i = a[0].length - 1; i >= 0; i--) {
		switch (k) {
			case 0:
				re = BB[7] + re;
				break;
			case 4:
				if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
					.test(a[0]))
					re = BB[4] + re;
				break;
			case 8:
				re = BB[5] + re;
				BB[7] = BB[5];
				k = 0;
				break;
		}
		if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
			re = AA[0] + re;
		if (a[0].charAt(i) != 0)
			re = AA[a[0].charAt(i)] + BB[k % 4] + re;
		k++;
	}
	if (a.length > 1) // 加上小数部分(如果有小数部分)
	{
		re += BB[6];
		for (var i = 0; i < a[1].length; i++)
			re += AA[a[1].charAt(i)];
	}
	if (re == '一十')
		re = "十";
	if (re.match(/^一/) && re.length == 3)
		re = re.replace("一", "");
	return re;
}
/*将数字转换为大写金额*/
export function changeToChinese(Num) {
	//判断如果传递进来的不是字符的话转换为字符
	if (typeof Num == "number") {
		Num = new String(Num);
	};
	Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
	Num = Num.replace(/ /g, "") //替换tomoney()中的空格
	Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
	if (isNaN(Num)) { //验证输入的字符是否为数字
		//alert("请检查小写金额是否正确");
		return "";
	};
	//字符处理完毕后开始转换，采用前后两部分分别转换
	var part = String(Num).split(".");
	var newchar = "";
	//小数点前进行转化
	for (var i = part[0].length - 1; i >= 0; i--) {
		if (part[0].length > 10) {
			return "";
			//若数量超过拾亿单位，提示
		}
		var tmpnewchar = ""
		var perchar = part[0].charAt(i);
		switch (perchar) {
			case "0":
				tmpnewchar = "零" + tmpnewchar;
				break;
			case "1":
				tmpnewchar = "壹" + tmpnewchar;
				break;
			case "2":
				tmpnewchar = "贰" + tmpnewchar;
				break;
			case "3":
				tmpnewchar = "叁" + tmpnewchar;
				break;
			case "4":
				tmpnewchar = "肆" + tmpnewchar;
				break;
			case "5":
				tmpnewchar = "伍" + tmpnewchar;
				break;
			case "6":
				tmpnewchar = "陆" + tmpnewchar;
				break;
			case "7":
				tmpnewchar = "柒" + tmpnewchar;
				break;
			case "8":
				tmpnewchar = "捌" + tmpnewchar;
				break;
			case "9":
				tmpnewchar = "玖" + tmpnewchar;
				break;
		}
		switch (part[0].length - i - 1) {
			case 0:
				tmpnewchar = tmpnewchar + "元";
				break;
			case 1:
				if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
				break;
			case 2:
				if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
				break;
			case 3:
				if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
				break;
			case 4:
				tmpnewchar = tmpnewchar + "万";
				break;
			case 5:
				if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
				break;
			case 6:
				if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
				break;
			case 7:
				if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
				break;
			case 8:
				tmpnewchar = tmpnewchar + "亿";
				break;
			case 9:
				tmpnewchar = tmpnewchar + "拾";
				break;
		}
		var newchar = tmpnewchar + newchar;
	}
	//小数点之后进行转化
	if (Num.indexOf(".") != -1) {
		if (part[1].length > 2) {
			// alert("小数点之后只能保留两位,系统将自动截断");
			part[1] = part[1].substr(0, 2)
		}
		for (i = 0; i < part[1].length; i++) {
			tmpnewchar = ""
			perchar = part[1].charAt(i)
			switch (perchar) {
				case "0":
					tmpnewchar = "零" + tmpnewchar;
					break;
				case "1":
					tmpnewchar = "壹" + tmpnewchar;
					break;
				case "2":
					tmpnewchar = "贰" + tmpnewchar;
					break;
				case "3":
					tmpnewchar = "叁" + tmpnewchar;
					break;
				case "4":
					tmpnewchar = "肆" + tmpnewchar;
					break;
				case "5":
					tmpnewchar = "伍" + tmpnewchar;
					break;
				case "6":
					tmpnewchar = "陆" + tmpnewchar;
					break;
				case "7":
					tmpnewchar = "柒" + tmpnewchar;
					break;
				case "8":
					tmpnewchar = "捌" + tmpnewchar;
					break;
				case "9":
					tmpnewchar = "玖" + tmpnewchar;
					break;
			}
			if (i == 0) tmpnewchar = tmpnewchar + "角";
			if (i == 1) tmpnewchar = tmpnewchar + "分";
			newchar = newchar + tmpnewchar;
		}
	}
	//替换所有无用汉字
	while (newchar.search("零零") != -1)
		newchar = newchar.replace("零零", "零");
	newchar = newchar.replace("零亿", "亿");
	newchar = newchar.replace("亿万", "亿");
	newchar = newchar.replace("零万", "万");
	newchar = newchar.replace("零元", "元");
	newchar = newchar.replace("零角", "");
	newchar = newchar.replace("零分", "");
	if (newchar.charAt(newchar.length - 1) == "元") {
		newchar = newchar + "整"
	}
	return newchar;
}
