/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import QS from 'qs';

import {
	apiurl,
	mpid
} from '@/utils/webconfig'
import {
	getSessionKey,
	getAccountInfo
} from '@/utils/localdatas'
import {
	isNullOrEmpty,
	isLocal
} from '@/utils/yqfCommon'

//export var apiurl = "https://3gapi.yiqifei.com";//正式环境
//export var apiurl = "https://3gapitest.yiqifei.com";//测试环境
//export var apiurl = "http://3gapilocal.yiqifei.com"; //本地环境

// export var apiurl = "http://fhtest.yiqifei.com";//测试环境
//export var apiurl = "http://fhlocal.yiqifei.com"; //本地环境

// 环境的切换
console.log(process.env.NODE_ENV);

// if (process.env.NODE_ENV == 'development') {
// 	axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {
// 	axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
// 	axios.defaults.baseURL = 'http://api.123dailu.com/';
// }

axios.defaults.baseURL = apiurl;
// 请求超时时间
axios.defaults.timeout = 50000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
// axios.interceptors.request.use(    
//     config => {
//         // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
//         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
//         const token = store.state.token;        
//         token && (config.headers.Authorization = token);        
//         return config;    
//     },    
//     error => {        
//         return Promise.error(error);    
//     })

// // 响应拦截器
// axios.interceptors.response.use(    
//     response => {        
//         if (response.status === 200) {            
//             return Promise.resolve(response);        
//         } else {            
//             return Promise.reject(response);        
//         }    
//     },
//     // 服务器状态码不是200的情况    
//     error => {        
//         if (error.response.status) {            
//             switch (error.response.status) {                
//                 // 401: 未登录                
//                 // 未登录则跳转登录页面，并携带当前页面的路径                
//                 // 在登录成功后返回当前页面，这一步需要在登录页操作。                
//                 case 401:                    
//                     router.replace({                        
//                         path: '/login',                        
//                         query: { redirect: router.currentRoute.fullPath } 
//                     });
//                     break;
//                 // 403 token过期                
//                 // 登录过期对用户进行提示                
//                 // 清除本地token和清空vuex中token对象                
//                 // 跳转登录页面                
//                 case 403:                     
//                     Toast({                        
//                         message: '登录过期，请重新登录',                        
//                         duration: 1000,                        
//                         forbidClick: true                    
//                     });                    
//                     // 清除token                    
//                     localStorage.removeItem('token');                    
//                     store.commit('loginSuccess', null);                    
//                     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
//                     setTimeout(() => {                        
//                         router.replace({                            
//                             path: '/login',                            
//                             query: { 
//                                 redirect: router.currentRoute.fullPath 
//                             }                        
//                         });                    
//                     }, 1000);                    
//                     break; 
//                 // 404请求不存在                
//                 case 404:                    
//                     Toast({                        
//                         message: '网络请求不存在',                        
//                         duration: 1500,                        
//                         forbidClick: true                    
//                     });                    
//                 break;                
//                 // 其他错误，直接抛出错误提示                
//                 default:                    
//                     Toast({                        
//                         message: error.response.data.message,                        
//                         duration: 1500,                        
//                         forbidClick: true                    
//                     });            
//             }            
//             return Promise.reject(error.response);        
//         }       
//     }
// );
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function ajaxGet(url, params, callback) {
	if (url.indexOf('http') != -1) {
		axios.defaults.baseURL = url;
	} else {
		axios.defaults.baseURL = apiurl;
	}
	// runAxios(url,params,'get',callback);
	// if (isLocal()) {
	// 	console.log(apiurl);
	// 	console.log(url);
	// }
	axios({
			method: 'get',
			url: url,
			params: params
		})
		.then(function(res) {
			// console.log(res);
			callback(res.data);
		})
		.catch(function(res) {
			// console.log(res);
			callback(getResponseData(-2, '请求异常', res));
		});

	// return new Promise((resolve, reject) => {
	// 	axios.get(url, {
	// 			params: params
	// 		})
	// 		.then(res => {
	// 			resolve(res.data);
	// 		})
	// 		.catch(err => {
	// 			reject(err.data)
	// 		})
	// });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function ajaxPost(url, params, callback) {

	if (url.indexOf('http') != -1) {
		axios.defaults.baseURL = url;
	} else {
		axios.defaults.baseURL = apiurl;
	}
	if (isLocal()) {
		console.log(axios.defaults.baseURL);
		// console.log(url);
		console.log("接口：【" + url + "】参数");
		console.log(params);
	}

	if (typeof params == 'object') {
		// console.log(JSON.stringify(obj));
		params = QS.stringify(params);
	}


	axios({
			method: 'post',
			url: url,
			data: params
		})
		.then(function(res) {
			if (isLocal()) {
				console.log("接口：【" + url + "】结果");
				console.log(res.data);
			}
			callback(res.data);
		})
		.catch(function(res) {
			if (isLocal()) {
				console.log("接口：【" + url + "】异常");
				console.log(res);
			}
			callback(getResponseData(-2, '请求异常', res));
		});
}
export function getAjaxRequest(ajaxUrl, parm, callback) {
	var parm = getParm(parm);
	// if (isLocal()) {
	// 	console.log(ajaxUrl);
	// 	console.log(parm);
	// }


	ajaxPost(ajaxUrl, parm, callback);
}
//发送POST请求
export function postAjaxRequest(ajaxUrl, parm, callback) {
	var parm = getParm(parm);
	// if (isLocal()) {
	// 	console.log(ajaxUrl);
	// 	console.log(parm);
	// }

	ajaxPost(ajaxUrl, parm, callback);
}


//错误时返回的JSON
export function getResponseData(code, msg, data) {
	var result = {
		code: code,
		msg: msg,
		data: null
	};
	return result;
}

//组装请求的参数
export function getParm(parm) {
	// console.log(mpid);
	if (!isNullOrEmpty(parm)) {
		parm.yqfkey = getSessionKey();
		parm.time = Date.now();
		parm.mpid = mpid;
		// parm.clientplatform = getClientPlatform();
	} else {
		parm = {
			yqfkey: getSessionKey(),
			time: Date.now(),
			mpid: mpid,
			// clientplatform: getClientPlatform()
		};
	}

	var accountinfo = getAccountInfo();
	if (accountinfo != null && accountinfo != undefined && accountinfo != '') {
		if (isNullOrEmpty(parm.useraccountno)) {
			parm.useraccountno = accountinfo.Account;
		}
		if (isNullOrEmpty(parm.userCompanyCode)) {
			parm.userCompanyCode = accountinfo.CompanyCode;
			parm.userCompanyName = accountinfo.CompanyName;
		}
		parm.userRoleID = accountinfo.RoleID;
	}
	// console.log(parm);
	return parm;
}
