import Vue from 'vue'
import Router from 'vue-router'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'

//游记头尾
import YoujiHeader from '@/pages/Youji/components/header.vue'
import YoujiFooter from '@/pages/Youji/components/footer.vue'


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location) {
//     return originalReplace.call(this, location).catch(err => err);
// };

export default new Router({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		//首页
		{
			path: '/',
			name: 'Home',
			components: {
				default: () => import('../pages/Home/index.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}

		},
		//机票
		{
			path: '/Flight',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/index.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		{
			path: '/Flight/success',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/success.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		{
			path: '/Flight/ShowDomesticFlight',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/ShowDomesticFlight.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		{
			path: '/Flight/ShowDomesticFlight',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/ShowDomesticFlight.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
        {
            path: '/Flight/domesticConfirm',
            name: 'Flight',
            components: {
                default: () => import('../pages/Flight/domesticConfirm.vue'),
                footer: Footer,
                header: Header
            },
            meta: {
                title: '一起飞国际机票'
            }
		
        },
		{
		    path: '/Flight/showIntlflight',
		    name: 'Flight',
		    components: {
		        default: () => import('../pages/Flight/showIntlflight.vue'),
		        footer: Footer,
		        header: Header
		    },
		    meta: {
		        title: '一起飞国际机票'
		    }
		
		},
		{
			path: '/Flight/showIntlflight',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/showIntlflight.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		{
			path: '/Flight/Confirm',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/Confirm.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		{
			path: '/Flight/xieyi',
			name: 'Flight',
			components: {
				default: () => import('../pages/Flight/xieyi.vue'),
			},
				
			
			meta: {
				title: '一起飞国际机票'
			}
		
		},
		/*酒店产品Start*/
		{
			path: '/Hotel',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/indexv2.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			} 
		},
		{
			path: '/Hotel/list',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/list.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			}
		},
		{
			path: '/Hotel/detail',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/detail.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			}
		},
		{
			path: '/Hotel/booking',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/booking.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			}
		},
		{
			path: '/Hotel/confirm',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/confirm.vue'),
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			}
		},
		
		{
			path: '/Hotel/success',
			name: 'Hotel',
			components: {
				default: () => import('../pages/Hotel/success.vue'),
				header: Header
			},
			meta: {
				title: '一起飞全球酒店'
			}
		},
		/*酒店产品End*/

		/*保险产品Start*/
		{
			path: '/Insurance', 
			name: 'Insurance',
			components: {
				default: () => import('../pages/Insurance/index.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险'
			}

		},
		{
			path: '/Insurance/list',
			name: 'Insurance',
			components: {
				default: () => import('../pages/Insurance/list.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险产品列表'
			}

		},
		{
			path: '/Insurance/detail',
			name: 'Insurance',
			components: {
				default: () => import('../pages/Insurance/detail.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险产品详情'
			}
		},		
		{
			path: '/Insurance/booking',
			name: 'Insurance',
			components: {
				default: () => import('../pages/Insurance/booking.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险产品资料录入'
			}
		},
		{
			path: '/Insurance/confirm',
			name: 'Insurance',
			components: {
				default: () => import('../pages/Insurance/confirm.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险产品资料确认'
			}
		},
		{
			path: '/Insurance/orderResult',
			name: 'orderResult',
			components: {
				default: () => import('../pages/Insurance/orderResult.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞保险产品'
			}
		},
		/*保险产品END*/
		
		/*签证产品Start*/
		{
			path: '/Visa', 
			name: 'Visa',
			components: {
				default: () => import('../pages/Visa/index.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞签证'
			}
		
		},
		{
			path: '/Visa/list',
			name: 'Visa',
			components: {
				default: () => import('../pages/Visa/list.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞签证产品列表'
			}
		
		},
		{
			path: '/Visa/detail',
			name: 'Visa',
			components: {
				default: () => import('../pages/Visa/detail.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞签证产品详情'
			}
		},		
		{
			path: '/Visa/booking',
			name: 'Visa',
			components: {
				default: () => import('../pages/Visa/booking.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞签证产品资料录入'
			}
		},
		{
			path: '/Visa/confirm',
			name: 'Visa',
			components: {
				default: () => import('../pages/Visa/confirm.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '一起飞签证产品资料确认'
			}
		},
		
		/*签证产品END*/
		/*预订结果页面*/
		{
			path: '/Order/orderResult',
			name: 'orderResult',
			components: {
				default: () => import('../pages/Order/orderResult.vue'),
				footer: Footer,
				header: Header
			},
			meta: {
				title: '预订结果'
			}
		},
        /*欧铁产品Start*/
        {
            path: '/Europe', 
            name: 'Europe',
            components: {
                default: () => import('../pages/Europe/index.vue'),
                footer: Footer,
                header: Header
            },
            meta: {
                title: '一起飞欧铁'
            }
		
        },
		{
		    path: '/Europe/list', 
		    name: 'list',
		    components: {
		        default: () => import('../pages/Europe/list.vue'),
		        footer: Footer,
		        header: Header
		    },
		    meta: {
		        title: '一起飞欧铁列表'
		    }
		
		},
        {
            path: '/Europe/list2', 
            name: 'list',
            components: {
                default: () => import('../pages/Europe/list2.vue'),
                footer: Footer,
                header: Header
            },
            meta: {
                title: '一起飞欧铁列表'
            }
		
        },
		{
		    path: '/Europe/detail', 
		    name: 'detail',
		    components: {
		        default: () => import('../pages/Europe/detail.vue'),
		        footer: Footer,
		        header: Header
		    },
		    meta: {
		        title: '一起飞欧铁详情'
		    }
		
		},
		{
		    path: '/Europe/booking', 
		    name: 'booking',
		    components: {
		        default: () => import('../pages/Europe/booking.vue'),
		        footer: Footer,
		        header: Header
		    },
		    meta: {
		        title: '填写资料'
		    }
		
		},
		/*欧铁产品end*/
		/*游记*/
		{
			path: '/Youji/index',
			name: 'YoujiIndex',
			components: {				
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/index.vue'),				
			},
			meta: {
			    title: '社区首页'
			}
		},
		{
			path: '/Youji/list',
			name: 'YoujiList',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/list.vue'),				
			},
			meta: {
			    title: '游记'
			}
		},
		{
			path: '/Youji/gonglue',
			name: 'YoujiGonglue',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/gonglue.vue'),				
			},
			meta: {
			    title: '攻略'
			}
		},
		{
			path: '/Youji/wenda',
			name: 'YoujiWenda',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/wenda.vue'),				
			},
			meta: {
			    title: '问答'
			}
		},
		{
			path: '/Youji/guwen',
			name: 'YoujiGuwen',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/guwen.vue'),				
			},
			meta: {
			    title: '顾问'
			}
		},
		{
			path: '/Youji/article',
			name: 'YoujiArticle',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/article.vue'),				
			},
			meta: {
			    title: '文章详情'
			}
		},
		/*游记end*/
		/*登录*/
		{
			path: '/Acccount/getLogin',
			name: 'getLogin',
			components: {
				default: () => import('../pages/Acccount/getLogin.vue'),				
			}
		},
		/*测试*/
		{
			path: '/Test/demo',
			name: 'Test',
			components: {
				default: () => import('../pages/Test/Demo.vue'),
				footer: Footer,
				header: Header
			}
		},
		{
			path: '/Youji/search',
			name: 'YoujiSearch',
			components: {
				header: YoujiHeader,
				footer: YoujiFooter,
				default: () => import('../pages/Youji/search.vue'),				
			},
			meta: {
			    title: '搜索'
			}
		},
	]
})
