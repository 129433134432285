import Vue from 'vue'
import Vuex  from 'vuex'
import router from '../router'
Vue.use(Vuex) 
import tableData from './moudle/tableData.js'
export default new Vuex.Store({
	 state:{routerUrl:'',routerName:''},
	 mutations: {
		/*路由跳转*/
	    changeView(state,playloadUrl) {
		  console.log(this.$route);
	      state.routerurl=playloadUrl
		  this.$router.push(playloadUrl);
	    }
	  },
	  actions:{
		 changeView(state,playloadUrl) {
		   console.log(this.$route);
		   state.routerurl=playloadUrl
		   this.$router.push(playloadUrl);
		 }
	  },
    modules: {
       tableData
    }
});