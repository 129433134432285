<template>
	<div class="pageHeader-all-box">
		<div class="pageHeader-top">
			<div class="container">
				<div class="zhuzhan-header">
					<div class="contact-box">
						<div class="head-toubu">
							<div class="head-toubua">
								<div class="head-toubub">欢迎来到我去过社交网站！</div>
								<div class="head-toubua">
									<div class="head-toubub">客服电话</div>
									<div class="head-toubuc">400-678-6622</div>
								</div>
							</div>
							<div class="head-toubud">
								<div class="head-toubue" v-for="(item,index) in headNavs" :key="index">
									<a href="javascript:;" @click="bindLink(item.link)" :to="item.link" >{{item.name}}</a>
								</div>
							</div>
						</div>
						<!-- <el-row type="flex" justify="space-between"> -->
						<!-- 原先代码 -->
							<!-- <div>
								<span>24小时服务热线：</span>
								<strong class="red">400-678-6622</strong>
								<a class="contact-list ml10" href="https://diy.yiqifei.com/addsimpleorder.aspx"
									title="写信给我们" target="_blank">
									<img src="https://tools.yiqifei.com/images/xinfeng.gif" class="i-middle" />
									<em class="i-middle">写信给我们</em>
								</a>
								<el-dropdown class="ml10">
									<span class="el-dropdown-link"><img
											src="//tools.yiqifei.com/images/weixin_s.gif" /></span>
									<el-dropdown-menu slot="dropdown">
										<div class="weixinbox"><img src="//tools.yiqifei.com/images/weixin.jpg"
												alt="" /></div>
									</el-dropdown-menu>
								</el-dropdown>
								<div class="loginstate" v-if="AccountInfo==null">
									<a href="javascript:;" @click="bindGetLogin">登录</a>
									&nbsp;/&nbsp;
									<a href="javascript:;" @click="bindRegister">注册</a>
								</div>
							</div> -->
							<!-- <div> -->
								<!-- <block v-if="AccountInfo!=null">
									<el-dropdown style="font-size:12px;" @command="handleCommand">
										<span class="el-dropdown-link">
											我的帐户
											<span class="iconfont icon-xiala"></span>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="https://accounts.yiqifei.com/">帐户首页
											</el-dropdown-item>
											<el-dropdown-item command="https://accounts.yiqifei.com/Order">我的订单
											</el-dropdown-item>
											<el-dropdown-item
												command="https://accounts.yiqifei.com/Account/MyIntergral">我的积分
											</el-dropdown-item>
											<el-dropdown-item command="https://accounts.yiqifei.com/">我的空间
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</block>
								<span class="ml10" @click="bindJifenJiangli">积分奖励</span> -->
								<!-- <span class="ml10">员工家园</span> -->
								<!-- <span class="link">繁體版(港幣價)</span> -->
							<!-- </div> -->
						<!-- </el-row> -->
					</div>
				</div>
			</div>
		</div>
		<div class="pageHeader-middle container clearfix">
			<div class="head-woquguo">
				<div class="">
					<a href="#/">
						<img src="//tools.yiqifei.com/images/logo.gif" >
					</a>
				</div>
				<div class="head-woquguoc">
					<div class="head-woquguoa">
						<el-input :placeholder="placeholdera" v-model="searhKey" class="input-with-select" @keydown.enter.native="bindSearch">
						   <!-- <el-select v-model="select" slot="prepend" placeholder="全部内容" class="head-woquguob">
								<el-option label="全部内容" value="1"></el-option>
								<el-option label="游记" value="2"></el-option>
								<el-option label="攻略" value="3"></el-option>
								<el-option label="优惠资讯" value="4"></el-option>
								<el-option label="问答" value="5"></el-option>
								<el-option label="旅行顾问" value="6"></el-option>
							</el-select> -->
							<el-button slot="append" icon="el-icon-search" @click="bindSearch"></el-button>
						</el-input>
					</div>
				</div>
				<!-- 登录前 -->
				<div class="head-denglu" v-if="AccountInfo==null">
					<div class="head-denglua">
						<a href="javascript:;" @click="bindGetLogin">登录</a>
					</div>
					<!-- <div class="head-denglub">
						<a href="javascript:;" @click="bindRegister">注册</a>
					</div> -->
				</div>
				<!-- 登录后 -->
				<!-- <div class="head-denglu">
					<div class="head-dengluc">
						<el-avatar size="number" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
					</div>
					<div class="head-dengluc">王楚钦</div>
				</div> -->
			</div>
		</div>
		<!-- <div class="pageHeader-middle container clearfix"> -->
			<!-- 原先头部图片加轮播图 -->
			<!-- <el-row type="flex" justify="space-between"> -->
				<!-- <el-row type="flex"> -->
					<!-- <div class="logobox">
						<a href="http://www.yiqifei.com/" class="logoimg">
							<img src="//tools.yiqifei.com/images/logo.gif"
								title="一起飞国际机票网：为您提供全球300多家航空公司往返约192个国家、4088个城市的特价机票" />
						</a>
					</div> -->
					<!-- <div class="actbox" style="padding:3px 0px;"><img src="../assets/zhongguo.png"
							style="height:90px;" /></div> -->
				<!-- </el-row> -->
				<!-- <div class="activebox">
					<iframe class="active-frame" marginwidth="0" marginheight="0" frameborder="0"
						src="//tools.yiqifei.com/transfer/titlead.aspx?adcode=19" allowtransparency="true"
						scrolling="no"></iframe>
				</div> -->
			<!-- </el-row> -->
		<!-- </div> -->
		<div class="pageHeader-bottom">
			<div class="int-nav-header">
				<el-row class="int-nav container" type="flex">
					<a href="javascript:;" @click="bindLink(item.link)" v-for="(item, index) in youjiHeadNavs" :key="index"
						:to="item.link" :class="item.link == path ? 'current' : ''">
						{{ item.name }}
					</a>
				</el-row>
			</div>
		</div>
		<div class="" >
			<div class="header-huidingbu" :class="gotopa" @click="toTo()">
				<div class="header-huidingbub"><img src="../../../assets/huidingb.png" ></div>
				<div class="header-huidingbuc"><img src="../../../assets/huidingbu.png" ></div>
				<div class="">回顶部</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	import {
		getAccountInfo,
		setAccountInfo,
		clearLoginInfo
	} from '@/utils/localdatas';	

	var that;
	var webconfig = require('@/utils/webconfig.js');

	export default {
		name: 'Header',
		props: {
			msg: String,
			// inputValue: String
		},
		setup(props) {
			// console.log(props.inputValue);
		},
		// watch() {
		// 	// that.path = this.$store.state.routerName;
		// 	// console.log(1);
		// },
		data() {
			return {
				headt:['国际机票','国内机票','全球酒店','国际度假','欧洲火车','签证','保险','差旅宝','活动频道','支付中心'],
				input3: '',
				select: '',
				placeholdera:['请输入您想搜索的内容'],
				returnurl: '/',
				path: '',
				
				headNavs: [{
						name: '首页',
						link: '/'
					},
					{
						name: '机票',
						link: '/Flight'
					},
					// { name: '国内机票', link: '/DomesticAir' },
					{
						name: '全球酒店',
						link: '/Hotel'
					},
					{
						name: '欧洲火车',
						link:'http://tour.yiqifei.com/Europe'
						// link: '/Europe'
					},
					{
						name: '签证',
						link:'http://tour.yiqifei.com/Visa'
						// link: '/Visa'
					},
					{
						name: '保险',
						link: '/Insurance'
					},
					{
						name: '差旅宝',
						link: 'https://biz.yiqifei.com'
					},
					{
						name: '支付中心',
						link: 'https://pay.yiqifei.com'
					},
					{
						name: '关于我们',
						link: 'http://ab.yiqifei.com'
					}
				],
				
				youjiHeadNavs: [{
						name: '社区首页',
						link: '/Youji/index'
					},
					{
						name: '游记',
						link: '/Youji/list'
					},
					// { name: '国内机票', link: '/DomesticAir' },
					// {
					// 	name: '攻略',
					// 	link: '/Youji/Gonglue'
					// },
					// {
					// 	name: '问答',
					// 	link:'/Youji/wenda'
					// 	// link: '/Europe'
					// },
					// {
					// 	name: '旅行顾问',
					// 	link:'/Youji/guwen'
					// 	// link: '/Visa'
					// },					
				],
				noticeCount: '',
				NoticeText: '',
				AccountInfo: null,
				searhKey:'',
				gotop: false,
				gotopa:''
			};
		},
		created(props) {
			//生命周期里接收参数
			that = this;
			that.path = that.$route.path;
			console.log(that.path);
			that.AccountInfo = getAccountInfo();
			console.log(that.AccountInfo);
			// console.log('1111' + that.$route.path);
		},
		mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			window.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			toTo() {
				$(window).scrollTop("0");
			},
			toTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			handleScroll() {
				let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
				scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
			},
			handleCommand(command) {
				// this.$message('click on item ' + command);
				window.location.href=command;
			},
			bindJifenJiangli:function(e){
				window.location.href="http://gift.yiqifei.com/";
			},
			bindLink: function(e) {
				console.log(e);
				let path = e;

				if (path.indexOf('http') != -1) {
					window.location.href = path;
				} else {
					that.path = path;
					that.$router.push({
						path: path
					});
				}
			},
			bindNoticeCenter(e) {
				that.$router.push({
					path: '/Profile/NoticeCenter'
				});
			},
			logout(e) {
				console.log(that.$route.path);
				clearLoginInfo();
				that.$router.push({
					path: '/login',
					query: {
						logout: true,
						returnurl: that.$route.path
					}
				});
			},
			logo() {
				console.log(1111);
				console.log(that.$route);
				var path = that.$route.path;
				that.path = this.$store.state.routerName;
				console.log(that.path + '44444');
				console.log(this.$store.state.routerName);
			},
			//登录
			bindGetLogin: function(e) {
				console.log(that.$route.path);
				// console.log(that.$route.query.delivery_asn);
				console.log(window.location.href);
				// var returnurl = that.$route.path;
				var returnurl =encodeURIComponent(window.location.href);
				// var loginurl=webconfig.yqfComApiurl+'/Account/JumpLogin?returnurl='+returnurl;
				// window.location.href=loginurl;
				util.getLogin(returnurl);
			},
			//注册
			bindRegister: function(e) {
				var returnurl = that.$route.path;

			},
			//搜索
			bindSearch:function(e){
				that.$router.push({ path: '/Youji/Search', query: { searhKey: that.searhKey } });		
			}
		}
	};	
	window.onscroll = function() {
		// 头部定位下拉后出现
		//scrollTop是浏览器滚动条的top位置，
		var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		//通过判断滚动条的top位置与可视网页之和与整个网页的高度来返回各项参数
		// console.log(scrollTop)
		if (scrollTop >= 1800) {
			that.gotopa = 'header-huidingbua'
		} else {
			that.gotopa = ''
		}
	}
	
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	@import '../../../style/youji/header.scss';
	
</style>
