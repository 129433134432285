//日期辅助

//计算历时
export function getCostTime(date1) {
	var times = {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	};
	var date2 = new Date(); //结束时间
	var date3 = date2.getTime() - date1.getTime() //时间差的毫秒数
	//计算出相差天数
	var days = Math.floor(date3 / (24 * 3600 * 1000))
	//计算出小时数
	var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
	//计算出小时数
	var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
	var hours = Math.floor(leave1 / (3600 * 1000))
	//计算相差分钟数
	var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
	var minutes = Math.floor(leave2 / (60 * 1000))
	//计算相差秒数
	var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
	var seconds = Math.round(leave3 / 1000)

	times.days = days;
	times.hours = hours;
	times.minutes = minutes;
	times.seconds = seconds;
	return times;
}
//日期大小判断，转成日期变量，然后比较符判断即可
export function compareDate(date1, date2) {
	try {
		var v1Ary = date1.split('-');
		var v2Ary = date2.split('-');
		var d1 = new Date(v1Ary[1] + '/' + v1Ary[2] + '/' + v1Ary[0]); //出发日期
		var d2 = new Date(v2Ary[1] + '/' + v2Ary[2] + '/' + v2Ary[0]); //返回日期
		console.log(d1);
		console.log(d2);
		if (d1 > d2) {
			return true;
		} else {
			return false;
		}
	} catch (e) {}
}
//日期格式化
Date.prototype.toString = function(format, loc) {
	var time = {};
	time.Year = this.getFullYear();
	time.TYear = ("" + time.Year).substr(2);
	time.Month = this.getMonth() + 1;
	time.TMonth = time.Month < 10 ? "0" + time.Month : time.Month;
	time.Day = this.getDate();
	time.TDay = time.Day < 10 ? "0" + time.Day : time.Day;
	time.Hour = this.getHours();
	time.THour = time.Hour < 10 ? "0" + time.Hour : time.Hour;
	time.hour = time.Hour < 13 ? time.Hour : time.Hour - 12;
	time.Thour = time.hour < 10 ? "0" + time.hour : time.hour;
	time.Minute = this.getMinutes();
	time.TMinute = time.Minute < 10 ? "0" + time.Minute : time.Minute;
	time.Second = this.getSeconds();
	time.TSecond = time.Second < 10 ? "0" + time.Second : time.Second;
	time.Millisecond = this.getMilliseconds();
	time.Week = this.getDay();

	var MMMArrEn = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	var MMMArr = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
	var WeekArrEn = ["Sun", "Mon", "Tue", "Web", "Thu", "Fri", "Sat"];
	var WeekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
	var WeekArr2 = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
	var oNumber = time.Millisecond / 1000;

	if (format != undefined && format.replace(/\s/g, "").length > 0) {
		if (loc != undefined && loc == "en") {
			MMMArr = MMMArrEn.slice(0);
			WeekArr = WeekArrEn.slice(0);
			WeekArr2 = WeekArrEn.slice(0);
		}
		format = format
			.replace(/yyyy/ig, time.Year)
			.replace(/yyy/ig, time.Year)
			.replace(/yy/ig, time.TYear)
			.replace(/y/ig, time.TYear)
			.replace(/MMM/g, MMMArr[time.Month - 1])
			.replace(/MM/g, time.TMonth)
			.replace(/M/g, time.Month)
			.replace(/dd/ig, time.TDay)
			.replace(/d/ig, time.Day)
			.replace(/HH/g, time.THour)
			.replace(/H/g, time.Hour)
			.replace(/hh/g, time.Thour)
			.replace(/h/g, time.hour)
			.replace(/mm/g, time.TMinute)
			.replace(/m/g, time.Minute)
			.replace(/ss/ig, time.TSecond)
			.replace(/s/ig, time.Second)
			.replace(/fff/ig, time.Millisecond)
			.replace(/ff/ig, oNumber.toFixed(2) * 100)
			.replace(/f/ig, oNumber.toFixed(1) * 10)
			.replace(/EEE/g, WeekArr[time.Week])
			.replace(/WWW/g, WeekArr2[time.Week]);
	} else {
		format = time.Year + "-" + time.Month + "-" + time.Day + " " + time.Hour + ":" + time.Minute + ":" + time
			.Second;
	}
	return format;
}
//DEMO
//var d = new Date();
//console.log(d.toString());    //2011-12-29 11:29:43
//console.log(d.toString(""));    //2011-12-29 11:29:43
//console.log(d.toString("yyyy-MM-dd"));    //2011-12-29
//console.log(d.toString("HH:mm:ss"));    //11:29:43
//console.log(d.toString("yyyy-MM-dd HH:mm:ss"));    //2011-12-29 11:29:43
//console.log(d.toString("yyyy年MM月dd日 HH:mm:ss"));    //2011年12月29日 11:29:43
//console.log(d.toString("yyyy-MM-dd HH:mm:ss fff"));    //2011-12-29 11:29:43 862

//天数加减
Date.prototype.addDays = function(value) {
	this.setDate(this.getDate() + value);
	return this.toString("yyyy-MM-dd");
}
//月份加减
Date.prototype.addMonth = function(value) {
	this.setMonth(this.getMonth() + value);
	return this.toString("yyyy-MM-dd");
}
//月份加减
Date.prototype.addYear = function(value) {
	this.setFullYear(this.getFullYear() + value);
	return this.toString("yyyy-MM-dd");
}

//格式化日期
export function DateTimeParse(datestr) {
	let dateTemp = datestr.split("-");
	let newdate = new Date(dateTemp[0], parseInt(dateTemp[1] - 1), dateTemp[2]);
	return newdate;
}

/*获取某月有多少天*/
export function getMonthOfDay(time) {
	var date = new Date(time)
	var year = date.getFullYear()
	var mouth = date.getMonth() + 1
	var days
	//当月份为二月时，根据闰年还是非闰年判断天数
	if (mouth == 2) {
		days = (year % 4 == 0 && year % 100 == 0 && year % 400 == 0) || (year % 4 == 0 && year % 100 != 0) ? 28 : 29
	} else if (mouth == 1 || mouth == 3 || mouth == 5 || mouth == 7 || mouth == 8 || mouth == 10 || mouth == 12) {
		//月份为：1,3,5,7,8,10,12 时，为大月.则天数为31；
		days = 31
	} else {
		//其他月份，天数为：30.
		days = 30
	}
	return days
}
/*获取某年有多少天*/
export function getYearOfDay(time) {
	var firstDayYear = this.getFirstDayOfYear(time);
	var lastDayYear = this.getLastDayOfYear(time);
	var numSecond = (new Date(lastDayYear).getTime() - new Date(firstDayYear).getTime()) / 1000;
	return Math.ceil(numSecond / (24 * 3600));
}
/*获取某年的第一天*/
export function getFirstDayOfYear(time) {
	var year = new Date(time).getFullYear();
	return year + "-01-01 00:00:00";
}
/*获取某年最后一天*/
export function getLastDayOfYear(time) {
	var year = new Date(time).getFullYear();
	var dateString = year + "-12-01 00:00:00";
	var endDay = this.getMonthOfDay(dateString);
	return year + "-12-" + endDay + " 23:59:59";
}
/*获取某个日期是当年中的第几天*/
export function getDayOfYear(time) {
	var firstDayYear = this.getFirstDayOfYear(time);
	var numSecond = (new Date(time).getTime() - new Date(firstDayYear).getTime()) / 1000;
	return Math.ceil(numSecond / (24 * 3600));
}
/*获取某个日期在这一年的第几周*/
export function getDayOfYearWeek(time) {
	var numdays = this.getDayOfYear(time);
	return Math.ceil(numdays / 7);
}
//天数差
export function DateDiff(inDate, outDate) {
	let dateSecond = outDate - inDate,
		days = Math.ceil(dateSecond / 1000 / 60 / 60 / 24);
	return days;
}
//计算两个日期之间的相差天数
export function getDaysBetween(dateString1, dateString2) {
	//计算两个日期之间的相差天数
	var startDate = Date.parse(dateString1);
	var endDate = Date.parse(dateString2);
	var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000) + 1;
	return days;
}
//时间转换
export function formatMsgTime(dateTimeStamp) {
	let result=dateTimeStamp;	
	var date = new Date(dateTimeStamp)
	var times=getCostTime(date);
	// console.log(times);
	if(times.days>0){
		result = "" + times.days+ "天前";
	}
	else if(times.hours>0){
		result = "" + times.hours+ "小时前";
	}
	else if(times.minutes>0){
		result = "" + times.minutes+ "分钟前";
	}
	else if(times.seconds>0){
		result = "刚刚";
	}
	return result;
};
