/*会员接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

//获取头部导航
export function GetHeadNav(parm, callback) {
	console.log('GetHeadNav');
	postAjaxRequest('/MemberAPI/GetHeadNav', parm, function(result) {
		callback(result);
	})	
}
//获取个人中心的导航菜单
export function GetProfileSlider(parm, callback) {
	console.log('GetProfileSlider');
	postAjaxRequest('/MemberAPI/GetProfileSlider', parm, function(result) {
		callback(result);
	})	
}
//获取管理的导航菜单
export function GetManagementSlider(parm, callback) {
	console.log('GetManagementSlider');
	postAjaxRequest('/MemberAPI/GetManagementSlider', parm, function(result) {
		callback(result);
	})	
}
//获取公告信息
export function GetNotice(parm, callback) {
	console.log('GetNotice');
	postAjaxRequest('/MemberAPI/GetNotice', parm, function(result) {
		callback(result);
	})	
}
//旅客信息
export function GetPassengerList(parm, callback) {
	console.log('GetPassengerList');
	postAjaxRequest('/MemberAPI/GetPassengerList', parm, function(result) {
		callback(result);
	})	
}
//获取常旅客信息
export function GetOftenPassengerList(parm, callback) {
	console.log('GetOftenPassengerList');
	postAjaxRequest('/MemberAPI/GetOftenPassengerList', parm, function(result) {
		callback(result);
	})	
}
//获取旅客信息
export function GetMPassengerInfo(parm, callback) {
	console.log('GetMPassengerInfo');
	postAjaxRequest('/MemberAPI/GetMPassengerInfo', parm, function(result) {
		callback(result);
	})	
}
//保存旅客信息
export function SaveBookPassenger(parm, callback) {
	console.log('SaveBookPassenger');
	postAjaxRequest('/MemberAPI/SaveBookPassenger', parm, function(result) {
		callback(result);
	})	
}

//获取用户的现金账户信息
export function GetLedgerAccountCash(parm, callback) {
	console.log('GetLedgerAccountCash');
	postAjaxRequest('/MemberAPI/GetLedgerAccountCash', parm, function(result) {
		callback(result);
	})	
}
//获取用户的现金支付方式
export function GetPayTypeList(parm, callback) {
	console.log('GetPayTypeList');
	postAjaxRequest('/MemberAPI/GetPayTypeList', parm, function(result) {
		callback(result);
	})	
}
//消费明细
export function GetAccountCashJournal(parm, callback) {
	console.log('GetAccountCashJournal');
	postAjaxRequest('/MemberAPI/GetAccountCashJournal', parm, function(result) {
		callback(result);
	})	
}
//获取用户现金券
export function GetCouponList(parm, callback) {
	console.log('GetCouponList');
	postAjaxRequest('/MemberAPI/GetCouponList', parm, function(result) {
		callback(result);
	})	
}
//现金券充值
export function GetRechargeCashCoupon(parm, callback) {
	console.log('GetRechargeCashCoupon');
	postAjaxRequest('/MemberAPI/GetRechargeCashCoupon', parm, function(result) {
		callback(result);
	})	
}
//获取消息列表
export function GetMessageList(parm, callback) {
	console.log('GetMessageList');
	postAjaxRequest('/MemberAPI/GetMessageList', parm, function(result) {
		callback(result);
	})	
}

//注册同行天地
export function GetRegistryMember(parm, callback) {
	console.log('GetRegistryMember');
	postAjaxRequest('/MemberAPI/GetRegistryMember', parm, function(result) {
		callback(result);
	})	
}
//获取人员信息
export function GetPersonalInfo(parm, callback) {
	console.log('GetPersonalInfo');
	postAjaxRequest('/MemberAPI/GetPersonalInfo', parm, function(result) {
		callback(result);
	})	
}
//更新人员信息
export function SavePersonalInfo(parm, callback) {
	console.log('SavePersonalInfo');
	postAjaxRequest('/MemberAPI/SavePersonalInfo', parm, function(result) {
		callback(result);
	})	
}
//获取部门信息
export function GetDeparmentList(parm, callback) {
	console.log('GetDeparmentList');
	postAjaxRequest('/MemberAPI/GetDeparmentList', parm, function(result) {
		callback(result);
	})	
}
//获取部门树形
export function GetDeparmentsTree(parm, callback) {
	console.log('GetDeparmentsTree');
	postAjaxRequest('/MemberAPI/GetDeparmentsTree', parm, function(result) {
		callback(result);
	})	
}
//获取部门下的所有下级部门
export function GetSubDeparment(parm, callback) {
	console.log('GetSubDeparment');
	postAjaxRequest('/MemberAPI/GetSubDeparment', parm, function(result) {
		callback(result);
	})	
}
//新增/编辑部门
export function EditDepartment(parm, callback) {
	console.log('EditDepartment');
	postAjaxRequest('/MemberAPI/EditDepartment', parm, function(result) {
		callback(result);
	})	
}
//删除部门
export function DeleteDepartment(parm, callback) {
	console.log('DeleteDepartment');
	postAjaxRequest('/MemberAPI/DeleteDepartment', parm, function(result) {
		callback(result);
	})	
}
//获取角色列表
export function GetRoleList(parm, callback) {
	console.log('GetRoleList');
	postAjaxRequest('/MemberAPI/GetRoleList', parm, function(result) {
		callback(result);
	})	
}
//保存权限设置
export function SaveRole(parm, callback) {
	console.log('SaveRole');
	postAjaxRequest('/MemberAPI/SaveRole', parm, function(result) {
		callback(result);
	})	
}
//删除角色
export function GetRoleDelete(parm, callback) {
	console.log('GetRoleDelete');
	postAjaxRequest('/MemberAPI/GetRoleDelete', parm, function(result) {
		callback(result);
	})	
}
//获取系统权限列表
export function GetSecurityResources(parm, callback) {
	console.log('GetSecurityResources');
	postAjaxRequest('/MemberAPI/GetSecurityResources', parm, function(result) {
		callback(result);
	})	
}
//获取员工
export function GetEmployeeByCondition(parm, callback) {
	console.log('GetEmployeeByCondition');
	postAjaxRequest('/MemberAPI/GetEmployeeByCondition', parm, function(result) {
		callback(result);
	})	
}
//获取员工信息
export function GetEmployeeInfo(parm, callback) {
	console.log('GetEmployeeInfo');
	postAjaxRequest('/MemberAPI/GetEmployeeInfo', parm, function(result) {
		callback(result);
	})	
}
//修改员工部门/政策中心/差旅政策
export function UpdateEmployeeExtension(parm, callback) {
	console.log('UpdateEmployeeExtension');
	postAjaxRequest('/MemberAPI/UpdateEmployeeExtension', parm, function(result) {
		callback(result);
	})	
}
//修改员工角色
export function ModifyRole(parm, callback) {
	console.log('ModifyRole');
	postAjaxRequest('/MemberAPI/ModifyRole', parm, function(result) {
		callback(result);
	})	
}
//修改状态
export function UpdateAccountStatus(parm, callback) {
	console.log('UpdateAccountStatus');
	postAjaxRequest('/MemberAPI/UpdateAccountStatus', parm, function(result) {
		callback(result);
	})	
}
//删除员工
export function DelEmployee(parm, callback) {
	console.log('DelEmployee');
	postAjaxRequest('/MemberAPI/DelEmployee', parm, function(result) {
		callback(result);
	})	
}
//新增/编辑用户资料
export function EditPersonInfo(parm, callback) {
	console.log('EditPersonInfo');
	postAjaxRequest('/MemberAPI/EditPersonInfo', parm, function(result) {
		callback(result);
	})	
}
//获取公司LOGO
export function GetCompanyLogo(parm, callback) {
	console.log('GetCompanyLogo');
	postAjaxRequest('/MemberAPI/GetCompanyLogo', parm, function(result) {
		callback(result);
	})	
}
//更新公司LOGO
export function UpdateCompanyLogo(parm, callback) {
	console.log('UpdateCompanyLogo');
	postAjaxRequest('/MemberAPI/UpdateCompanyLogo', parm, function(result) {
		callback(result);
	})	
}
//修改密码
export function UpdatePassword(parm, callback) {
	console.log('UpdatePassword');
	postAjaxRequest('/MemberAPI/UpdatePassword', parm, function(result) {
		callback(result);
	})	
}
//修改证件
export function ModifyCertificaty(parm, callback) {
	console.log('ModifyCertificaty');
	postAjaxRequest('/MemberAPI/ModifyCertificaty', parm, function(result) {
		callback(result);
	})	
}
//修改用户航司
export function UpdataMileCard(parm, callback) {
	console.log('UpdataMileCard');
	postAjaxRequest('/MemberAPI/UpdataMileCard', parm, function(result) {
		callback(result);
	})	
}
//公司公告
export function GetBulletinList(parm, callback) {
	console.log('GetBulletinList');
	postAjaxRequest('/MemberAPI/GetBulletinList', parm, function(result) {
		callback(result);
	})	
}
//保存
export function SaveBulletinList(parm, callback) {
	console.log('SaveBulletinList');
	postAjaxRequest('/MemberAPI/SaveBulletinList', parm, function(result) {
		callback(result);
	})	
}
//删除
export function BulletinDelete(parm, callback) {
	console.log('BulletinDelete');
	postAjaxRequest('/MemberAPI/BulletinDelete', parm, function(result) {
		callback(result);
	})	
}

//提交反馈
export function SubmitFeedBack(parm, callback) {
	console.log('SubmitFeedBack');
	postAjaxRequest('/MemberAPI/SubmitFeedBack', parm, function(result) {
		callback(result);
	})	
}
//开通账户
export function OpenAccount(parm, callback) {
	console.log('OpenAccount');
	postAjaxRequest('/MemberAPI/OpenAccount', parm, function(result) {
		callback(result);
	})	
}