import router from './router'
import Vue from 'vue'
import Vuex  from 'vuex'
import App from './App'
import moment from 'moment'
import VueCompositionApi from '@vue/composition-api';
import ElementUI from 'element-ui';
import VueSuperSlide from 'vue-superslide';
import Pagination from 'vue-pagination-2';
import('element-ui/lib/theme-chalk/index.css');
import store from './store';
import ('./style/commom.scss');
import $ from 'jquery';
Vue.use(Vuex);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(VueCompositionApi);
Vue.use(VueSuperSlide)
Vue.component('pagination', Pagination);
Vue.prototype.$moment = moment
moment.locale('zh-cn');
router.beforeEach((to,from,next)=>{
   // 路由发生变化修改标题
	window.document.title = to.meta.title == undefined ? "一起飞国际机票网" : to.meta.title
	// if (to.meta.title) {
	//   document.title = to.meta.title
	// }
    console.log(to.path+'3333');
    store.state.routerName=to.name;
    next();
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
