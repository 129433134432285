/*通用类库*/
import {
	ajaxGet,
	ajaxPost
} from '@/utils/request';
import {
	setStorage,
	getStorage
} from '@/utils/storagehelper';
import {
	getAccountInfo
} from '@/utils/localdatas';

import router from '@/router'

var webconfig = require('@/utils/webconfig.js');


var YqfSessionKey = "_yqfSessionKey_fhpc";

export var mpid = "26"; //对应的公众号ID
export var apiUrl = webconfig.apiurl;
export var yqfApiurl = webconfig.yqfApiurl;
export var uploadDomain = webconfig.uploadDomain;
export var mapApiUrl = webconfig.mapApiUrl;

export function formatTime(date) {
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	var hour = date.getHours();
	var minute = date.getMinutes();
	var second = date.getSeconds();
	return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

export function formatNumber(n) {
	n = n.toString()
	return n[1] ? n : '0' + n
}

export function isNullOrEmpty(obj) {
	return obj == null || obj == '' || obj == undefined || obj == "null";
}
//唯一标识
export function newGuid() {
	var guid = "";
	for (var i = 1; i <= 32; i++) {
		var n = Math.floor(Math.random() * 16.0).toString(16);
		guid += n;
		if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
			guid += "-";
	}
	return guid;
}
export function checkLogin() {
	return getAccountInfo() != null;
}
//检查用户并登录
export function getLogin(returnurl) {
	// if(!checkLogin()){
	// 	router.push({
	// 		path: '/login',
	// 		query: {
	// 			logout: true,
	// 			returnurl: returnurl
	// 		}
	// 	});
	// 	return false;
	// }
	var loginurl=webconfig.yqfComApiurl+'/Account/JumpLogin?returnurl='+returnurl;
	window.location.href=loginurl;
}

export function getAccountCode() {
	let accountCode = '';
	if (checkLogin()) {
		accountCode = getAccountInfo().Account;
	}
	return accountCode;
}
//保存的唯一标识
export function getSessionKey() {
	var SessionKey = getStorage(YqfSessionKey);
	console.log(SessionKey);
	if (SessionKey == null || SessionKey == "") {
		SessionKey = newGuid();
		setStorage(YqfSessionKey, SessionKey);
		// uni.setStorage({
		// 	key: _yqfSessionKey,
		// 	data: SessionKey
		// })
	}
	console.log(SessionKey);
	return SessionKey;
}

//发送GET请求
export function getAjaxRequest(ajaxUrl, parm, callback) {
	ajaxGet(ajaxUrl, getParm(parm), function(result) {
		callback(result);
	})
}
//发送POST请求
export function postAjaxRequest(ajaxUrl, parm, callback) {
	ajaxPost(ajaxUrl, getParm(parm), function(result) {
		callback(result);
	})
}

function getParm(parm) {
	if (!isNullOrEmpty(parm)) {
		parm.yqfkey = getSessionKey();
		parm.time = Date.now();
		parm.mpid = mpid;
	} else {
		parm = {
			yqfkey: getSessionKey(),
			time: Date.now(),
			mpid: mpid
		};
	}
	if (checkLogin()) {
		var accountinfo = getAccountInfo();
		if (isNullOrEmpty(parm.useraccountno)) {
			parm.useraccountno = accountinfo.Account;
		}
		if (isNullOrEmpty(parm.userCompanyCode)) {
			parm.userCompanyCode = accountinfo.CompanyCode;
			parm.userCompanyName = accountinfo.CompanyName;
		}
		parm.userRoleID=accountinfo.RoleID;
	}
	parm.clientplatform = "PC";
	// console.log(parm);
	return parm;
}


//跳转支付
export function getPay(orderno, amount) {
	let accountinfo = getAccountInfo();
	let PayLoginAccount="";
	if(accountinfo!=null){
		PayLoginAccount=accountinfo.Account;
	}
	let returnUrl=encodeURIComponent(webconfig.domainUrl + '/Profile/OrderDetail?id=' + orderno + '');
	let payUrl =
		'https://pay.yiqifei.com/pay/GetPay?PayMoney=' +
		amount +
		'&PayLoginAccount=' +
		PayLoginAccount +
		'&OrderId=' +
		orderno +
		'&returnurl=' + returnUrl;
	window.open(payUrl);
}

export function getCerTypeName(cerType) {
	switch (cerType) {
		//ID-身份证，PP-护照，NI-其他证件
		case 'ID':
			return '身份证';
		case 'PP':
			return '护照';
		case '10':
			return '军人证';
		case '11':
			return '回乡证';
		case '12':
			return '台胞证';
		case '13':
			return '港澳通行证';
		case 'NI':
			return '其他证件';
	}
	return cerType;
}
export function getInsuranceRoleName(InsuranceRole) {
	console.log(InsuranceRole);
	switch (InsuranceRole) {		
		case 1:
			return '投保人&被保人';
		case 2:
			return '投保人';
		case 3:
			return '被保人';		
	}
	return InsuranceRole;
}