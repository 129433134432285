import { setStorage, getStorage,deleteStorage,setSessionStorage,getSessionStorage } from '@/utils/storagehelper';
import { TicketSearchInfo,HotelSearchInfo,InsurSearchInfo,TrainTicketSearchInfo } from '@/data/staticData';

var _kevVersion = "fhv1";
var _yqfSessionKey = "_yqfSessionKey";
//账号
var AccountInfoKey="_yqfaccountinfov6";
//机票
var TicketSearchInfoKey="_yqfticketsearchv7";
//火车票
var TrainTicketSearchInfoKey="_yqfTrainTicketSearchv2";
//酒店
var HotelSearchInfoKey="_yqfHotelSearchInfoKeyv3";
//保险
var InsurSearchInfoKey="_yqfInsurSearchInfoKeyv2";
//签证产品的KEY
var visaInfoListKey = "visaInfoList_key";
var visaBookInfoListKey = "visaBookInfoList_key";
var visapPassengerListKey = "visaPassengerList_key";
//预定的信息
var BookingFormInfoKey="_yqfbookingforminfov1";
var HotelBookingFormInfoKey="_yqfhotelbookingforminfov1";
//改签的旅客
var ChangePassengersKey="_ChangePassengersKeyv1";

//保存的唯一标识
export function getSessionKey() {
	var SessionKey = getStorage(_yqfSessionKey);
	if (SessionKey == null || SessionKey == "") {
		var guid = "";
		for (var i = 1; i <= 32; i++) {
			var n = Math.floor(Math.random() * 16.0).toString(16);
			guid += n;
			if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
				guid += "-";
		}

		SessionKey = guid;
		setStorage(_yqfSessionKey, SessionKey);
	}
	return SessionKey;
}
//清除登录的信息
export function clearLoginInfo(){
	deleteStorage(AccountInfoKey);
	deleteStorage(BookingFormInfoKey);
	deleteStorage(HotelBookingFormInfoKey);
	deleteStorage(ChangePassengersKey);	
}
//获取用户信息
export function getAccountInfo(){
	// return getStorage(AccountInfoKey);	
	let info=getSessionStorage(AccountInfoKey);
	// console.log(info)
	return info;
}
export function setAccountInfo(obj){
	// setStorage(AccountInfoKey,obj);
	setSessionStorage(AccountInfoKey,obj);
}

//机票查询信息
export function getTicketSearchInfo(){
	let ticketSearchInfo = getStorage(TicketSearchInfoKey);
	if (ticketSearchInfo == null) {
		ticketSearchInfo = TicketSearchInfo;
	}
	if (ticketSearchInfo.MultSegments == null) {
		let MultSegments = [];
		MultSegments.push({
			DepartCityCode: ticketSearchInfo.DepartCityCode,
			DepartCityName: ticketSearchInfo.DepartCityName,
			DepartInputVal: ticketSearchInfo.DepartInputVal,
			ArrivalCityCode: ticketSearchInfo.ArrivalCityCode,
			ArrivalCityName: ticketSearchInfo.ArrivalCityName,
			ArrivalInputVal: ticketSearchInfo.ArrivalInputVal,
			DepartDate: ticketSearchInfo.StartDate
		});
		MultSegments.push({
			DepartCityCode: ticketSearchInfo.ArrivalCityCode,
			DepartCityName: ticketSearchInfo.ArrivalCityName,
			DepartInputVal: ticketSearchInfo.ArrivalInputVal,
			ArrivalCityCode: ticketSearchInfo.DepartCityCode,
			ArrivalCityName: ticketSearchInfo.DepartCityName,
			ArrivalInputVal: ticketSearchInfo.DepartInputVal,
			DepartDate: ticketSearchInfo.ReturnDate
		});
		ticketSearchInfo.MultSegments = MultSegments;
	}
	return ticketSearchInfo;
}
export function setTicketSearchInfo(obj){
	setStorage(TicketSearchInfoKey,obj);
}
//酒店查询信息
export function getHotelSearchInfo(){
	var obj=getStorage(HotelSearchInfoKey);
	if (obj == null) {
		obj = HotelSearchInfo;
	}
	return obj;	
}
export function setHotelSearchInfo(obj){
	setStorage(HotelSearchInfoKey,obj);
}
//火车票查询信息
export function getTrainTicketSearchInfo(){
	var obj=getStorage(TrainTicketSearchInfoKey);
	if (obj == null) {
		obj = TrainTicketSearchInfo;
	}
	return obj;	
}
export function setTrainTicketSearchInfo(obj){
	setStorage(TrainTicketSearchInfoKey,obj);
}
//旅客提交的信息
export function getBookingFormInfo(){
	return getStorage(BookingFormInfoKey);	
}
export function setBookingFormInfo(obj){
	setStorage(BookingFormInfoKey,obj);
}
//酒店预订提交的信息
export function getHotelBookingFormInfo(){
	return getStorage(HotelBookingFormInfoKey);	
}
export function setHotelBookingFormInfo(obj){
	setStorage(HotelBookingFormInfoKey,obj);
}

//保险
export function getInsurSearchInfo(){
	var obj=getStorage(InsurSearchInfoKey);
	if (obj == null) {
		obj = InsurSearchInfo;
	}
	return obj;	
}
export function setInsurSearchInfo(obj){
	setStorage(InsurSearchInfoKey,obj);
}

//改签的旅客信息
export function getChangePassengersInfo(){
	return getStorage(ChangePassengersKey);	
}
export function setChangePassengersInfo(obj){
	setStorage(ChangePassengersKey,obj);
}

//预订的签证产品信息
export function getvisaBookInfo() {
	return getStorage(visaBookInfoListKey);
}
export function setvisaBookInfo(obj) {
	return setStorage(visaBookInfoListKey, obj);
}
export function getVisaPassengerList() {
	return getStorage(visapPassengerListKey);
}
export function setVisaPassengerList(obj) {
	return setStorage(visapPassengerListKey, obj);
}