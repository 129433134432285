/*一起飞官网项目配置*/
export var mpid = "11"; //对应的公众号ID

//3GAPI地址
export var yqfApiurl = "https://3gapitest.yiqifei.com";//正式环境
//export var yqfApiurl = "http://3gapilocal.yiqifei.com"; //本地测试环境

//上传图片的地址
export var uploadDomain = "https://3gapitest.yiqifei.com/Upload/"; //正式
//export var uploadDomain = "http://3gapilocal.yiqifei.com/Upload/"; //本地测试
//地图地址
export var mapApiUrl = "https://feihuo.yiqifei.com/MapAPI/BDMap"; 

//apiurl请求地址
export var apiurl = "https://feihuo.yiqifei.com";//正式环境
//export var apiurl = "http://fhtest.yiqifei.com";//正式环境 
// export var apiurl = "http://fhlocal.yiqifei.com"; //本地测试

//apiurl请求地址
export var yqfComApiurl = "https://www.yiqifei.com";//正式环境
// export var yqfComApiurl = "http://yqflocal.yiqifei.com";//本地环境


// export var domainUrl = "http://fhtest.yiqifei.com/pc/#"; //域名

export var domainUrl = "https://www.yiqifei.com/pc/#"; //域名

//版本号
export var versioncode = "2.0.3"; //版本号

/*一起飞官网项目配置*/